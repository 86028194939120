import {Offer} from '@/models/simulation/Simulation';
import {SimulationDemand} from '@/models/Demand';
import {ScheduleVariant} from '@/components/calculator/schedule/models/ScheduleVariant';
import {isEmpty} from 'lodash-es';

export interface ScheduleRow {
  actualCapital: number;
  capitalFee: number;
  capitalPaid: number;
  currentMonth: number;
  interestFee: number;
  monthlyFee: number;
  earlyPayment?: number;
  interestSubsidy?: number;
  additionalCosts: {name: string; fee: number}[];
}

export interface ScheduleRowWithVisible extends ScheduleRow {
  visible: boolean;
}

export enum ScheduleCostType {
  PROVISION = 'provision',
  BRIDGING_INSURANCE = 'bridgingInsurance',
  REAL_ESTATE_INSURANCE = 'realEstateInsurance',
  LIFE_INSURANCE = 'lifeInsurance',
  LOW_PERSONAL_SHARE_INSURANCE = 'lowPersonalShareInsurance',
  PAYMENT_INSURANCE = 'paymentInsurance',
  INSURANCE_PACKAGE = 'insurancePackage',
  SECOND_INSURANCE_PACKAGE = 'secondInsurancePackage',
  CREDIT_CARD = 'creditCard',
  BANK_ACCOUNT = 'bankAccount',
  REAL_ESTATE_APPRAISAL = 'realEstateAppraisal',
  BROKER_COMMISSION = 'brokerCommission',
  OPERATING_FEE = 'operatingFee',
  FIRST_INSURANCE = 'firstInsurance',
  SECOND_INSURANCE = 'secondInsurance',
  THIRD_INSURANCE = 'thirdInsurance',
  FOURTH_INSURANCE = 'fourthInsurance',
  FIFTH_INSURANCE = 'fifthInsurance',
}
export interface SummaryCost {
  name: ScheduleCostType;
  total: number;
  costs: {description: string; fee: number}[];
}
export type HomeStartProgram = Nullable<{
  numberOfAdult: number,
  subsidizedLoanAmount: number,
  numberOfChildren: number
  unsubsidizedLoanAmount: number,
  maxSubsidizedLoanAmount: number,
  weightedAverageInterestRate: number,
  incomeBasedSubsidyReduction: number,
  areaBasedSubsidyReduction: number,
  subsidyReductionSum: number,
  totalInterestSubsidy: number,
  preferenceInterestRate: number,
  incomeReductionThreshold: number | null,
  propertyAreaReductionThreshold: number,

}>
export interface Schedule {
  schedules: ScheduleRow[];
  totalInterestFee: number;
  summaryCosts: SummaryCost[];
  homeStartProgram?: HomeStartProgram
}

export interface ScheduleEarlyPayment {
  month?: number;
  payment?: number;
}

export interface ScheduleChangingMargin {
  initialValue?: number
  eventualValue?: number
  initialPeriod?: number;
  initialValueStatic?: boolean;
  eventualValueStatic?: boolean;
}

export type HomeStartProgramInput = {
  numberOfAdult: Nullable<number>;
  numberOfChildren?: Nullable<number>;
  city?: Nullable<string>;
  propertyArea: Nullable<number>;
  incomeSum: Nullable<number>;
  isBuildingHouse: boolean;
};

export class ScheduleParams {
  capital: number;
  loanMonth: number;
  margin: number;
  referancyRate: number;
  shouldReduceLoanPeriod: boolean = false;
  isFixedInstallments: boolean = true;
  earlyPayment: ScheduleEarlyPayment[] = [];
  changingMargin: Nullable<ScheduleChangingMargin> = null;
  isSafeCredit2Percent: boolean = false;
  userInput: Nullable<SimulationDemand> = null;
  offerId: Nullable<string> = null;
  simulationId: Nullable<string> = null;
  isHomeStartProgram: boolean;

  constructor(
    offer: Offer,
    userInput: SimulationDemand,
    scheduleVariant: ScheduleVariant,
    earlyPayment: ScheduleEarlyPayment[],
    loanPeriodInMonths: number,
    simulationId: string,
    isHomeStartProgram: boolean,
  ) {
    this.offerId = offer.info.id;
    this.simulationId = simulationId;
    this.capital = offer.totals.capital;
    this.loanMonth = loanPeriodInMonths;
    this.margin = this.calculateMargin(offer);
    this.referancyRate = offer.interestRate.referenceRate;
    this.isFixedInstallments = userInput?.fixedInstallments;
    if (offer.changingMargin) {
      this.changingMargin = {
        initialPeriod: offer.changingMargin?.initialPeriod,
        initialValueStatic: offer.changingMargin?.initialValueStatic,
        eventualValueStatic: offer.changingMargin?.eventualValueStatic,
        initialValue: offer.changingMargin?.initialValue!,
        eventualValue: offer.changingMargin?.eventualValue!,
      };
    }
    this.userInput = userInput;
    this.isSafeCredit2Percent = Boolean(offer.features.isSafeCredit2Percent);
    this.shouldReduceLoanPeriod = scheduleVariant === ScheduleVariant.WITH_ADDITIONAL_PAYMENT_SHORTENED_PERIOD;
    this.earlyPayment = scheduleVariant === ScheduleVariant.WITHOUT_ADDITIONAL_PAYMENT
      ? [] : earlyPayment.filter((earlyPayment) => !!earlyPayment.payment && !!earlyPayment.month);
    this.isHomeStartProgram = isHomeStartProgram;
  }

  calculateMargin(offer: Offer): number {
    // Dla ofert ze stałą marżą i oprocentowaniem równym marży,
    // marża jest równa różnicy między marżą a stopą referencyjną.
    // Jest to rozwiązanie pod specjalny przypadek w ofertach rumuńskich.
    if (!offer.changingMargin && offer.interestRate.sum === offer.interestRate.margin) {
      return offer.interestRate.margin - offer.interestRate.referenceRate;
    }
    return offer.interestRate.margin;
  }
}
