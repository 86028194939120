import {VueConstructor} from 'vue';
import ProcessInfoToolbar from '@/components/commons/ProcessInfoToolbar.vue';
import VListLoanApplication from '@/components/wizard/VListLoanApplication.vue';
import LoaderButton from '@/components/commons/LoaderButton.vue';
import DevComponent from '@/env/DevComponent.vue';
import AsyncSpan from '@/components/commons/AsyncSpan.vue';
import MultiformSection from '@/modules/multiForm/views/MultiformSection.vue';
import FpExpansion from '@/components/commons/FpExpansion.vue';
import FpDateTimeView from '@/components/commons/FpDateTimeView.vue';
import XSellVariantsRadio from '@/components/commons/calculator/XSellVariantsRadio.vue';
import {VAvatar, VBadge, VProgressLinear, VSwitch, VTooltip} from 'vuetify/lib/components';
import FpMarkdown from '@/components/commons/FpMarkdown.vue';
import FpInput from '@/components/fpComponents/FpInput.vue';
import FpSelect from '@/components/fpComponents/FpSelect.vue';
import FpInternationalNumber from '@/components/fpComponents/FpInternationalNumber.vue';
import FpBtn from '@/components/fpComponents/FpBtn.vue';
import LDOT from '@/components/utils/LDot.vue';
import FpRadioGroup from '@/components/fpComponents/FpRadioGroup.vue';
import DOT from '@/components/utils/DOT.vue';

const CommonComponents = {
  install(Vue: VueConstructor) {
    Vue.component('dev-info', DevComponent);
    Vue.component('process-info-toolbar', ProcessInfoToolbar);
    Vue.component('v-list-loan-application', VListLoanApplication);
    Vue.component('download-button', LoaderButton);
    Vue.component('async-span', AsyncSpan);
    Vue.component('multiform-section', MultiformSection);
    Vue.component('fp-expansion', FpExpansion);
    Vue.component('fp-date-time-view', FpDateTimeView);
    Vue.component('XSellVariantsRadio', XSellVariantsRadio);
    Vue.component('VTooltip', VTooltip);
    Vue.component('VProgressLinear', VProgressLinear);
    Vue.component('VSwitch', VSwitch);
    Vue.component('FpMarkdown', FpMarkdown);
    Vue.component('VAvatar', VAvatar);
    Vue.component('VBadge', VBadge);
    Vue.component('LDOT', LDOT);

    // Good old friend FpComponents
    Vue.component('FpInput', FpInput);
    Vue.component('FpSelect', FpSelect);
    Vue.component('FpInternationalNumber', FpInternationalNumber);
    Vue.component('fp-btn', FpBtn);
    Vue.component('loading-button', FpBtn);
    Vue.component('FpRadioGroup', FpRadioGroup);
  },
};
export default CommonComponents;
