import BaseStoreService from '@/store/BaseStoreService';
import {
  RestrictedDictionaryKeys,
  DictionaryStoreState,
  NonAuthDictionaryKeys
} from '@/commons/services/store/DictionaryStoreState';
import { DictionaryStoreModule } from '@/commons/services/store/DictionaryStoreModule';
import DictionaryApi from '@/commons/services/DictionaryApi';
import AuthService from '@/modules/user/AuthService';
import { object } from 'underscore';
import { BankSymbol } from '@/commons/dictionaries/BankSymbol';
import ClientAuthService from '@/modules/client/services/ClientAuthService';
import { NotInstantBlockQuizzes, QuizzesRequire, QuizzesRequireHrLockAt } from '@/models/Quiz';

class DictionaryStore extends BaseStoreService<DictionaryStoreState> {
  private apiState: { [key in keyof DictionaryStoreState]: boolean } = {
    documentIncomeTypes: false,
    documentTypes: false,
    documentPeriods: false,
    documentValidityPeriods: false,
    bankBranchesDictionary: false,
    bankDictionary: false,
    discussionTagsDictionary: false,
    divisionsDictionary: false,
    partnerDictionary: false,
    prospectLossReasonDictionary: false,
    multiDocumentCategoryDictionary: false,
    landsDictionary: false,
    districtsDictionary: false,
    regionsDictionary: false,
  };

  mutations = DictionaryStoreModule.mutations;
  getters = DictionaryStoreModule.getters as
    { [key in keyof DictionaryStoreState]: (state: DictionaryStoreState) => any };

  get getApiState(): { [key in keyof DictionaryStoreState]: boolean } {
    return this.apiState;
  }

  public getDictionary<T extends keyof DictionaryStoreState>(key: T): DictionaryStoreState[T] {
    const dictionary = this.read<DictionaryStoreState[typeof key]>(this.getters[key]);
    // TODO: dictionary.length === 0 powoduje zapętlenie się w fetchDictionary gdy zwraca pusty Array.
    if (dictionary.length === 0 && !this.apiState[key]) {
      this.fetchDictionary(key);
    }
    return dictionary || [];
  }

  async getAsyncDictionary<T extends keyof DictionaryStoreState>(key: T): Promise<DictionaryStoreState[T]> {
    let dictionary = this.read<DictionaryStoreState[typeof key]>(this.getters[key]);
    dictionary = await this.fetchDictionary(key);
    return dictionary || [];
  }

  public async getDictionaries<K extends keyof DictionaryStoreState>(...keys: (K)[]):
    Promise<Pick<DictionaryStoreState, K>> {
    const list = await Promise.all(keys.map(async (key) => {
      const dictionary = await this.getDictionary(key);
      return [key, dictionary,];
    }));
    return object(list) as any;
  }

  public async getPartnerSources() {
    return this.getDictionary('partnerDictionary').flatMap(x => x.sources);
  }

  public async getBankBranchByBank(bankName: BankSymbol) {
    const branches = await this.getAsyncDictionary('bankBranchesDictionary');
    return branches ? branches.filter(el => el.type === bankName) : [];
  }

  public async fetchAllDictionaries(): Promise<void> {
    const dictionaryKeys = Object.keys(DictionaryStoreModule.state) as (keyof DictionaryStoreState)[];
    await Promise.all(dictionaryKeys.map(key => this.fetchDictionary(key)));
  }

  public addToDictionary<T extends DictionaryStoreState[keyof DictionaryStoreState][number]>(
    key: keyof DictionaryStoreState,
    newElement: T): void {
    this.commit(this.mutations.addToDictionary, { key, newElement, });
  }

  canAccessDictionaries(key: keyof DictionaryStoreState): boolean {
    const clientUser = ClientAuthService.User!;
    if (clientUser) {
      return NonAuthDictionaryKeys.includes(key);
    }
    const user = AuthService.User!;
    if (!user) {
      return false;
    }

    const privileges = user.privileges;
    // w okresie przejsciowym musi byc takie rozwiazanie
    const quiz = Object.entries(user.quiz!).reduce<boolean[]>((acc: boolean[], [key, value,]) => {
      if (!NotInstantBlockQuizzes.includes(key as keyof QuizzesRequire) && typeof value === 'boolean') {
        acc.push(value);
      }
      return acc;
    }, []);
    // Był tu dość zawiły warunek, uznałem, że go napisze najbardziej łopatologicznie jak sie da.
    if (RestrictedDictionaryKeys.includes(key)) {
      return user.isAdmin || user.isVerifier || user.isChiefOfDivision || user.isReportDepartment;
    } else if (NonAuthDictionaryKeys.includes(key)) {
      return true;
    } else {
      return !privileges.requireAccreditation &&
        !privileges.isHrLocked &&
        quiz.every(element => element === false);
    }
  }

  public async fetchDictionary<T extends keyof DictionaryStoreState>(key: T): Promise<DictionaryStoreState[T]> {
    if (this.canAccessDictionaries(key)) {
      try {
        this.apiState[key] = true;
        const response = (await DictionaryApi[key]()) as DictionaryStoreState[T];
        this.commit(this.mutations.setDictionary, { key: key, dictionary: response, });
        return response;
      } catch (e) {
        console.error(e);
        return [];
      } finally {
        this.apiState[key] = false;
      }
    } else {
      console.warn(`Cannot access "${key}"`);
      return [];
    }
  }
}

export default new DictionaryStore();
