<template>
  <v-row no-gutters>
    <v-col>
      <fp-loader global class="fp-loader"/>
      <v-hover v-slot="{hover}" v-if="!hideNavigation">
        <v-navigation-drawer
          v-model="navbar"
          :permanent="$vuetify.breakpoint.smAndUp"
          :touchless="$vuetify.breakpoint.smAndUp"
          :mini-variant="isMiniMenu"
          :mini-variant-width="MINI_VARIANT_WIDTH"
          :expand-on-hover="isMiniMenu"
          :class="{'min-nav-icon-fix': isMiniMenu && !$vuetify.breakpoint.xsOnly}"
          class="secondary left-navbar-menu left-menu-layer"
          app
          dark>
          <v-layout column fill-height>
            <v-flex shrink class="logo-sticky secondary">
              <v-layout column>
                <v-flex>
                  <v-app-bar :height="$vuetify.breakpoint.xsOnly ? MOBILE_APP_BAR_HEIGHT : DESKTOP_APP_BAR_HEIGHT" flat class="secondary">
                    <v-list v-if="!$env.isLendi">
                      <v-list-item>
                        <v-list-item-title class="title">
                          {{ $env.mode }} -- version
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-layout align-center>
                      <base-navigation-logo :hover="hover" :is-mini-menu="isMiniMenu" :readonly="readonly"></base-navigation-logo>
                      <transition name="fade">
                        <v-flex v-if="!isMiniMenu || hover" shrink class="d-flex">
                          <v-tooltip class="left-menu-layer pr-2" bottom >
                            <template v-slot:activator="{on}">
                              <div :class="$onboarding.tours.DASHBOARD.step.THEME_COLOR">
                                <v-icon @click.stop="onThemeToggle" v-on="on">{{$vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night'}}</v-icon>
                              </div>
                            </template>
                            <span>{{themeColorI18n}}</span>
                          </v-tooltip>
                          <v-tooltip v-if="$vuetify.breakpoint.smAndUp" class="left-menu-layer" bottom>
                            <template v-slot:activator="{on}">
                              <div :class="$onboarding.tours.DASHBOARD.step.MENU_SIZE" class="ml-3">
                                <span v-on="on">
                                  <v-icon @click.stop="onMenuToggle" :disabled="minNavigation">{{isMiniMenu ? 'mdi-forwardburger' : 'mdi-backburger'}}</v-icon>
                                </span>
                              </div>
                            </template>
                            <span v-if="!minNavigation"> {{ changeMenuSizeI18n }}</span>
                            <span v-else>{{$i18n.PlatformSection.menu_size_not_available}}</span>
                          </v-tooltip>
                        </v-flex>
                      </transition>
                    </v-layout>
                  </v-app-bar>
                </v-flex>
                <v-flex class="px-4 pb-3">
                  <div :class="$onboarding.tours.DASHBOARD.step.USERNAME">
                  <user-button
                      v-if="!$scopedSlots.user && $user"
                      :icon="isMiniMenu && !hover"
                      :name="$user.displayName"
                      >
                    <template #menu>
                      <v-list dense>
                        <slot name="user-menu"/>
                        <v-list-item @click="logOut()">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $i18n.useUserPanel.LOG_OUT }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                  </user-button>
                  <slot v-else name="user" :minNavigation="isMiniMenu"/>
                    </div>
                </v-flex>
                <v-flex>
                  <v-divider/>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 :id="$cy.navigationWrapper"  class="primaryBg--text navigation-wrapper overflow-y-auto">
              <slot name="drawer" :mini-variant="miniVariant"/>
            </v-flex>
            <v-divider/>
            <v-flex shrink px-4 my-2 v-if="$vuetify.breakpoint.lgAndUp">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-layout column>
                    <v-flex v-if="$env.isDev ||$env.isPreprod">
                      <fp-btn @click="changeSystemClock">{{ $i18n.PlatformSection.change_time }}</fp-btn>
                      <fp-btn @click="resetSystemClock">{{ $i18n.PlatformSection.reset }}</fp-btn>
                      <fp-input v-model="newTime"/>
                    </v-flex>
                    <v-flex v-on="on" d-flex>
                      <v-icon size="22" color="rgba(225, 225, 225, .87)" class="mr-1">
                        mdi-alpha-c-circle-outline
                      </v-icon>
                      <span class="text-bearly-white note" v-if="!isMiniMenu">
                        <template v-if="$env.isProd">Lendi Sp. z o.o.</template>
                        <template v-else-if="$env.isPreprod">Preprod mode {{day | dateFormat}}</template>
                        <template v-else-if="$env.isDev">Dev Mode {{day | dateFormat}}</template>
                      </span>
                    </v-flex>
                    <v-flex v-if="!isMiniMenu && $env.isPreprodOrDev" v-on="on" d-flex>
                      <v-icon size="22" color="rgba(225, 225, 225, .87)" class="mr-1">
                        mdi-dev-to
                      </v-icon>
                      <span class="text-bearly-white note">Build date: {{ buildDate }}</span>
                    </v-flex>
                  </v-layout>
                </template>
                <span class="legal-note">
                  {{ $i18n.useBaseNavigation.SOFTWARE_BY }}
                </span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
      </v-hover>
      <v-app-bar
          v-if="!hideNavigation && ($scopedSlots['bar'] || $vuetify.breakpoint.smAndDown)"
          :class="{'fixed-left-padding': isMiniMenu && $vuetify.breakpoint.smAndUp}"
          flat app
          class="top-navigation"
          :height="DESKTOP_APP_BAR_HEIGHT">
        <v-icon
          class="mobile-menu"
          @click="navbar = true"
          v-if="$vuetify.breakpoint.xsOnly">
          menu
        </v-icon>
        <slot name="bar"/>
      </v-app-bar>
      <fp-loader global class="fp-loader"/>
      <v-main :class="{'fixed-left-padding': !hideNavigation && isMiniMenu && $vuetify.breakpoint.smAndUp}">
        <v-container fluid class="pos-container" pa-0 grid-list-lg>
          <v-alert v-if="showVersionAlert" text type="info" prominent class="mb-0" icon="mdi-star-face">
            <v-row align="center" no-gutters>
              <v-col class="grow">
                {{ $i18n.useBaseNavigation.CURRENTLY_OUTDATED }}
              </v-col>
              <v-col class="shrink">
                <v-btn text dark class="primaryBg primary--text" @click="reload()">{{ $i18n.useButtonCommons.REFRESH }}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <slot/>
        </v-container>
      </v-main>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import VersionCheck from '@/services/versionCheck/VersionCheck';
import UserButton from '@/modules/navigation/UserButton.vue';
import AuthService from '@/modules/user/AuthService';
import ConfigurationApi from '@/modules/configuration/services/ConfigurationApi';
import {StorageKeys} from '@/commons/enums/StorageKeys';
import {Utils} from '@/services/utils/BasicUtils';
import {TransitionParams} from '@/models/Transition';
import {getConfigBooleanValue, saveConfigValue, toggleTheme} from '@/services/theme/ThemeService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {Route} from 'vue-router';
import {Routes} from '@/router/Routes';
import moment from 'moment';
import {computed, getCurrentInstance, onMounted, provide, ref, watch} from 'vue';
import GoToService from '@/router/GoToService';
import BaseNavigationLogo from '@/modules/navigation/BaseNavigationLogo.vue';

const MOBILE_APP_BAR_HEIGHT = '40px';
const DESKTOP_APP_BAR_HEIGHT = '56px';
const MINI_VARIANT_WIDTH = '80px';

const props = withDefaults(defineProps<{
  readonly?: boolean;
  minNavigation?: boolean;
  hideNavigation?: boolean;
}>(), {
  readonly: false,
  minNavigation: false,
  hideNavigation: false,
});

const day = ref<string>('');
const newTime = ref<string>('2019-04-29T10:15:30.00Z');
const miniVariant = ref<boolean>(props.minNavigation);
const navbar = ref<boolean>(false);

const instance = getCurrentInstance();
/*  This ref is injected in MyCalendar.vue and DashboardActivities.vue with use useActivityRefreshFromNotification.ts
    and in NotificationBar.vue !!!!It's a workaround !!!!->
    only way to get a db's user's activities listener is to listen on notification's socket in NotificationService.ts */
const areActivitiesToBeFetched = ref<boolean>(false);
provide('areActivitiesToBeFetched', areActivitiesToBeFetched);
const isOnboardingEnabled = async() => await instance?.proxy.$onboarding.tours.DASHBOARD.isEnabled();

watch(() => instance?.proxy.$route, async(route?: Route) => {
  if (route?.name === Routes.DASHBOARD && await isOnboardingEnabled()) {
    miniVariant.value = false;
  }
});

const changeSystemClock = async() => {
  await ConfigurationApi.setSystemClock(JSON.stringify({instant: newTime.value,}));
  day.value = await ConfigurationApi.getSystemClock();
};

const resetSystemClock = async() => {
  await ConfigurationApi.resetSystemClock();
  day.value = await ConfigurationApi.getSystemClock();
};

onMounted(async() => {
  if (AuthService.User && !AuthService.User?.privileges.isHrLocked && await isOnboardingEnabled()) {
    miniVariant.value = false;
  } else {
    miniVariant.value = getConfigBooleanValue(StorageKeys.MINI_MENU);
  }
  try {
    if (instance?.proxy.$env.isPreprodOrDev) {
      day.value = await ConfigurationApi.getSystemClock();
    }
  } catch (e) {
    instance?.proxy.errorHandler(e, I18NGetter().useBaseNavigation.FAILED_TO_FETCH_DATE);
  }
});

const reload = (): void => {
  window.location.reload();
};

const isMiniMenu = computed<boolean>(
  () => instance!.proxy.$vuetify.breakpoint.smAndUp && (miniVariant.value || props.minNavigation)
);

const themeColorI18n = computed<string>(
  () => instance!.proxy.$vuetify.theme.dark
    ? I18NGetter().usePlatformSection.NEW_TRANSLATION_CHOOSE_THEME_COLOR_LIGHT
    : I18NGetter().usePlatformSection.NEW_TRANSLATION_CHOOSE_THEME_COLOR_DARK,
);

const changeMenuSizeI18n = computed<string>(
  () => isMiniMenu.value
    ? I18NGetter().usePlatformSection.NEW_TRANSLATION_MAXIMIZE
    : I18NGetter().usePlatformSection.NEW_TRANSLATION_MINIFY
);

const showVersionAlert = computed<boolean>(() => VersionCheck.ApplicationObservable.requireReload);

const logOut = () => {
  AuthService.logout();
};

const buildDate = computed<string>(() => moment(instance?.proxy.$env.buildDate).format('DD.MM.YYYY HH:mm'));

const onLogoClick = () => {
  if (!props.readonly) {
    GoToService.dashboard();
  }
};

const onThemeToggle = () => {
  const transitionParams = new TransitionParams({callback: toggleTheme, properties: ['background-color', 'color', 'opacity',],});
  Utils.makeTransition(transitionParams);
};
const onMenuToggle = () => {
  miniVariant.value = !miniVariant.value;
  saveConfigValue(StorageKeys.MINI_MENU, miniVariant.value);
};
</script>

<style lang="scss">
@import '@/assets/styles/base/breakpoints';
$menu-horizontal-padding: 16px;

.logo-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.legal-note-flex {
  position: absolute;
  bottom: 16px;

  .legal-note {
    font-size: 12px;
    /* To nie koliduje z darkiem */
    color: #f9fbff;
  }
}

.mobile-position-fix {
  .v-toolbar__content {
    padding-right: 0;
  }
}

/*FAB fix for moz*/
.v-btn--floating {
  & .v-btn__content {
    height: 24px;
  }
}

.a-btn {
  height: 18px;
  margin: 0;

  .v-btn__content {
    border-radius: 4px;
    height: 18px;
    margin: 0;
    padding: 0 4px;
    text-transform: none;
  }
}

.v-badge__badge {
  font-size: 12px;
  opacity: 1;
}

.material-icons {
  display: inherit;
}

.logo-footer {
  width: 70px;
}

.text-bearly-white {
  color: var(--v-primaryBg-base);

  &.note {
    font-size: 14px;
    align-self: flex-end;
  }

  &.v-list-item--active {
    color: var(--v-secondaryBg-base) !important;

    .v-icon {
      color: var(--v-secondaryBg-base) !important
    }
  }
}

.theme--dark.v-subheader {
  color: hsla(0, 0%, 100%, $disabled-opacity)
}

.top-navigation {
  background-color: var(--v-primaryBg-base) !important;
  z-index: 8 !important;

  &:before {
    content: "";
    height: 1px;
    width: 100%;
    border-bottom: 1px solid fpShadow($border-opacity);
    position: fixed;
    top: 56px;
    background: var(--v-primaryBg-base);
  }
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item.v-list-item--link {
  justify-content: flex-start;

  & > *:first-child {
    margin-right: 0 !important;
  }
}

.min-nav-icon-fix {
  .v-list-group__items {
    & > div > * {
      justify-content: normal !important;
    }
  }

  &.v-navigation-drawer--is-mouseover {
    .v-list-item__title {
      display: block !important;
    }
  }

  .v-list-item--link .v-list-item__action, .v-list-group__header__prepend-icon {
    margin-right: 0 !important;
    padding: 0 !important;
  }

  &.v-navigation-drawer--is-mouseover {
    .v-list-item--link .v-list-item__action, .v-list-group__header__prepend-icon {
      margin-right: 16px !important;
    }
  }
}

.v-list-item--active:hover:before,
.theme--dark.v-list-item--active:before {
  opacity: 0 !important;
}

.left-navbar-menu {
  /*  na iphone/android zakrywa ostatnie buttony avigacji */
  @media (max-width: #{map-get($breakpoints, sm)}) {
    & {
      height: 100dvh !important;

    }
  }

  .v-list-group {
    position: relative;

    &:before {
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      -webkit-transition: .3s cubic-bezier(.25, .8, .5, 1);
      transition: .3s cubic-bezier(.25, .8, .5, 1);
      width: 100%;
    }

    &.v-list-group--active:before {
      border-left: $primary 4px solid;
      height: 100%;
    }
  }
}

.left-menu-layer {
  z-index: $navigation-z-index !important;
}

.fixed-left-padding {
  padding-left: 80px !important;
  left: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition-delay: .2s;
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
