export enum ProductType {
  MORTGAGE = 'productTypeMortgage',
  CASH = 'productTypeCash',
  SME = 'productTypeSme',
  NONBANK = 'productTypeNonBank',
  REAL_ESTATE = 'productTypeRealEstate',
  INSURANCE = 'productTypeInsurance',
  FACTORING = 'productTypeSmeFactoring',
  LEASING = 'productTypeSmeLeasing',
  INVESTMENT = 'productTypeInvestment',
  INSURANCE_REAL_ESTATE = 'productTypeInsuranceRealEstate',
  INSURANCE_LIFE = 'productTypeInsuranceLife',
  INSURANCE_MOTOR_VEHICLE = 'productTypeInsuranceMotorVehicle',
  INSURANCE_TRAVEL = 'productTypeInsuranceTravel',
}
