import {
  OfferDataTableElementKey,
  OfferDataTableGroupKey,
  OfferTableElement
} from '@/components/calculator/results/offers/OfferTableElement';
import { Cost, Offer } from '@/models/simulation/Simulation';
import { numeralFormat } from '@/filters/StringFilters';
import {
  CreditCard,
  getInsuranceElement, getNumeralOrEmptyWithCurrency, getNumeralOrEmptyWithNothing, getNumeralOrEmptyWithPercent,
  InterestRateSum, LoanAmount, LoanPeriod, MarginRow,
  Provision, renderFirstInstallment
} from '@/components/calculator/results/tableElements/CommonTableElements';
import { TableElementHeight } from '@/components/calculator/results/tableElements/TableElementsConstants';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import EnvironmentService from '@/env/EnvironmentService';
import AuthService from '@/modules/user/AuthService';

const currentCurrency = EnvironmentService.Environment.currentCurrency();

export const CashTableElements: OfferTableElement[] = [
  {
    header: () => I18NGetter().useCashTableElements.INSTALLMENT,
    key: OfferDataTableElementKey.FIRST_INSTALLMENT,
    height: TableElementHeight.INPUT,
    open: false,
    render: (x: Offer) =>
      `<span class="font-weight-medium">${numeralFormat(x.overview.firstInstallment)} ${EnvironmentService.Environment.currentCurrency()}</span>`,
    children: [
      {
        header: () => I18NGetter().useCashTableElements.INSTALLMENT,
        key: OfferDataTableElementKey.FIRST_INSTALLMENT_FIRST_INSTALLMENT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.firstInstallment)}</span>`,
      },
      {
        header: () => I18NGetter().useCashTableElements.FIRST_INSURANCE,
        key: OfferDataTableElementKey.FIRST_INSTALLMENT_FIRST_INSURANCE,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.insurances?.first?.[0]?.fee)}</span>`,
      },
      {
        header: () => I18NGetter().useCashTableElements.SECOND_INSURANCE,
        key: OfferDataTableElementKey.FIRST_INSTALLMENT_SECOND_INSURANCE,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.insurances?.second?.[0]?.fee)}</span>`,
      },
      {
        header: () => I18NGetter().useMortgageTableElements.HEADER_PERSONAL_ACCOUNT,
        key: OfferDataTableElementKey.FIRST_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees?.bankAccount?.fee)}</span>`,
      },
    ],
  },
  {
    header: () => I18NGetter().useMortgageTableElements.HEADER_TRANSITIONAL_INSTALLMENT,
    key: OfferDataTableElementKey.TRANSITIONAL_INSTALLMENT,
    height: TableElementHeight.INPUT,
    render: (x: Offer) => `<span class="font-weight-medium">${getNumeralOrEmptyWithCurrency(x.overview.firstInstallment)}</span>`,
    open: false,
    children: [
      {
        header: () => I18NGetter().useCashTableElements.INSTALLMENT,
        key: OfferDataTableElementKey.TRANSITIONAL_INSTALLMENT_INSTALLMENT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.firstInstallment)} </span>`,
      },
      {
        header: () => I18NGetter().useCashTableElements.FIRST_INSURANCE,
        key: OfferDataTableElementKey.TRANSITIONAL_INSTALLMENT_FIRST_INSURANCE,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.insurances?.first?.[0]?.fee)} </span>`,
      },
      {
        header: () => I18NGetter().useMortgageTableElements.HEADER_PERSONAL_ACCOUNT,
        key: OfferDataTableElementKey.TRANSITIONAL_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees?.bankAccount?.fee)} </span>`,
      },
    ],
  },
  {
    header: () => I18NGetter().useMortgageTableElements.HEADER_TARGET_INSTALLMENT,
    key: OfferDataTableElementKey.TARGET_INSTALLMENT,
    height: TableElementHeight.INPUT,
    render: (x: Offer) => `<span class="font-weight-medium">${getNumeralOrEmptyWithCurrency(x.overview.lastInstallment)} </span>`,
    open: false,
    children: [
      {
        header: () => I18NGetter().useCashTableElements.INSTALLMENT,
        key: OfferDataTableElementKey.TARGET_INSTALLMENT_INSTALLMENT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.lastInstallment)} </span>`,
      },
      {
        header: () => I18NGetter().useCashTableElements.FIRST_INSURANCE,
        key: OfferDataTableElementKey.TARGET_INSTALLMENT_FIRST_INSURANCE,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees.insurances?.first?.[0]?.fee)} </span>`,
      },
      {
        header: () => I18NGetter().useMortgageTableElements.HEADER_PERSONAL_ACCOUNT,
        key: OfferDataTableElementKey.TARGET_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT,
        render: (x: Offer) => `<span>${getNumeralOrEmptyWithCurrency(x.monthlyFees?.bankAccount?.fee)} </span>`,
      },
    ],
  },
  {
    header: () => I18NGetter().useMortgageTableElements.HEADER_PAYMENT_SCHEDULE,
    height: TableElementHeight.INPUT,
    printHeight: TableElementHeight.DEFAULT,
    key: OfferDataTableElementKey.PAYMENT_SCHEDULE,
    runtimeTemplate: true,
    render: () => `
              <div>
                    <v-btn text outlined @click="openScheduleModal()">${I18NGetter().useHeaderContent.GENERATE}</v-btn>
              </div>`,
  },
  {
    header: () => I18NGetter().useMortgageTableElements.HEADER_PAYMENT_SCHEDULE,
    height: TableElementHeight.INPUT,
    printHeight: TableElementHeight.DEFAULT,
    key: OfferDataTableElementKey.PAYMENT_SCHEDULE,
    runtimeTemplate: true,
    render: () => `
              <div>
                    <v-btn text outlined @click="openScheduleModal()">${I18NGetter().useHeaderContent.GENERATE}</v-btn>
              </div>`,
  },
  LoanPeriod,
  LoanAmount,
  {
    header: () => I18NGetter().useCashTableElements.TOTAL_COSTS,
    groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
    key: OfferDataTableElementKey.COSTS_SUM,
    render: (x: Offer) => `${numeralFormat(x.totals.costsSum)} ${EnvironmentService.Environment.currentCurrency()}`,
  },
  {
    header: () => I18NGetter().useCashTableElements.LOAN_AMOUNT_GROSS,
    groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
    key: OfferDataTableElementKey.LOAN_AMOUNT_GROSS,
    render: (x: Offer) => `${numeralFormat(x.totals.capital)} ${EnvironmentService.Environment.currentCurrency()}`,
  },
  {
    header: () => I18NGetter().useCashTableElements.INITIAL_COSTS,
    groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
    key: OfferDataTableElementKey.INITIAL_COST,
    render: (x: Offer) => `${numeralFormat(x.overview.initialCost)} ${EnvironmentService.Environment.currentCurrency()}`,
  },
  {
    header: () => I18NGetter().useCashTableElements.RRSO,
    groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
    key: OfferDataTableElementKey.RRSO,
    render: (x: Offer) => `${x.rrso} %`,
    condition: () => EnvironmentService.Environment.isAppDomain().NESTO_RO ? AuthService.User?.isAdminOrVerifier! : true,
  },
  {
    header: () => I18NGetter().useCashTableElements.TOTAL_SUM,
    groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
    key: OfferDataTableElementKey.TOTAL_SUM,
    render: (x: Offer) => `${numeralFormat(x.totals.totalSum)} ${EnvironmentService.Environment.currentCurrency()}`,
  },
  MarginRow,
  Provision,
  InterestRateSum,
  {
    header: () => I18NGetter().useMortgageTableElements.HEADER_PERSONAL_ACCOUNT,
    key: OfferDataTableElementKey.PERSONAL_ACCOUNT,
    groupKey: OfferDataTableGroupKey.X_SELL_GROUP,
    height: TableElementHeight.EXTRA_LARGE,
    printHeight: 110,
    runtimeTemplate: true,
    class: 'd-block text-wrap overflow-y-auto',
    render: (x: Offer) => x.monthlyFees?.bankAccount ? `
            <div class="pa-1">
             <span class="primary--text font-weight-medium body-1 d-flex align-center"><v-icon class="pr-2 primary--text">mdi-information-outline</v-icon>${I18NGetter().useHeaderContent.REQUIRED}</span>
              <p class="mb-1 font-weight-medium body-1">${x.monthlyFees?.bankAccount.name}</p>
              <p class="mb-1 font-weight-medium">${x.monthlyFees?.bankAccount.fee}${currentCurrency} / ${I18NGetter().useHeaderContent.MONTHLY}</p>
              <p class="caption mb-1">${x.monthlyFees?.bankAccount.description}</p>
            </div>
            ` : `
            <div>
                <span class="error--text font-weight-medium body-1">${I18NGetter().useHeaderContent.NOT_REQUIRED}</span>
            </div>
            `,
    printRender: (x: Offer) => x.monthlyFees?.bankAccount ? `
            <div class="pa-1">
             <span class="primary--text font-weight-medium print print-font-size-11 d-flex align-center"><v-icon class="pr-1 primary--text body-1">mdi-information-outline</v-icon>${I18NGetter().useHeaderContent.REQUIRED}</span>
              <p class="mb-1 font-weight-medium print-font-size-11">${x.monthlyFees?.bankAccount.name}</p>
              <p class="mb-1 font-weight-medium print-font-size-11">${x.monthlyFees?.bankAccount.fee}${currentCurrency} / ${I18NGetter().useHeaderContent.MONTHLY}</p>
              <p class="mb-1 font-weight-regular">${x.monthlyFees?.bankAccount.description}</p>
            </div>
            ` : `
            <div>
                <span class="error--text font-weight-medium body-2">${I18NGetter().useHeaderContent.NOT_REQUIRED}</span>
            </div>
            `,
  },
  CreditCard,
  {
    header: () => I18NGetter().useCashTableElements.DESC,
    key: OfferDataTableElementKey.DESCRIPTION,
    height: TableElementHeight.EXTRA_LARGE,
    class: 'd-block text-wrap overflow-y-auto caption',
    runtimeTemplate: true,
    render: () => '<fp-markdown v-once class="text-left print-font-size-10  caption" :source="offer.info.description"></fp-markdown>',
  },
  {
    header: () => I18NGetter().useCashTableElements.INSURANCE_INFO,
    key: OfferDataTableElementKey.ADDITIONAL_PRODUCTS_INFO,
    groupKey: OfferDataTableGroupKey.X_SELL_GROUP,
    height: TableElementHeight.LARGE,
    class: 'd-block text-wrap overflow-y-auto caption',
    runtimeTemplate: true,
    render: () => '<fp-markdown v-once class="text-left" :source="offer.info.insurancesDescription"></fp-markdown>',
  },
  getInsuranceElement(
    OfferDataTableElementKey.FIRST_INSURANCE,
    () => I18NGetter().useCashTableElements.FIRST_INSURANCE,
    Cost.FIRST_INSURANCE,
    OfferDataTableGroupKey.X_SELL_GROUP
  ),
  getInsuranceElement(
    OfferDataTableElementKey.SECOND_INSURANCE,
    () => I18NGetter().useCashTableElements.SECOND_INSURANCE,
    Cost.SECOND_INSURANCE,
    OfferDataTableGroupKey.X_SELL_GROUP
  ),
].filter(element => !EnvironmentService.Environment.getAppDomainConfig().calculator.excludedSimulationRows.productTypeCash
  .includes(element.key)
)
  .map(element => element.children
    ? ({
      ...element,
      children: element.children.filter(
        child => !EnvironmentService.Environment.getAppDomainConfig().calculator.excludedSimulationRows.productTypeCash
          .includes(child.key)
      ),
    }) : element);
