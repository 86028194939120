<template>
  <v-expansion-panels
      accordion
      multiple
  >
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        <v-row>
          <v-col cols="auto">
            <div v-if="scheduleStore.schedule?.summaryCosts?.length">
            <v-switch
                @click.prevent.stop
                v-model="scheduleStore.showAdditionalCosts"
                hide-details class="mt-0"
                :label="$i18n.useScheduleModal.ADDITIONAL_COSTS"/>
            </div>
            <div v-else>
              <span class="font-weight-medium">{{I18NGetter().useScheduleModal.ADDITIONAL_COSTS_NONE}}</span>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col cols="10">
            <schedule-additional-costs-table v-if="scheduleStore.schedule?.summaryCosts?.length"/>
            <p v-else>{{I18NGetter().useScheduleModal.OFFER_NO_ADDITIONAL_COSTS}}</p>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <p class="body-1 mb-0 font-weight-medium">{{ $i18n.useScheduleModal.EARLY_PAYMENTS }}</p>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col class="py-0">
            <changes-detection :value="model" @change="fetchSchedule">
              <template>
                <v-data-table
                    v-if="scheduleStore.earlyPayment.length"
                    disable-pagination
                    :items="scheduleStore.earlyPayment"
                    hide-default-footer
                    calculate-widths
                    mobile-breakpoint="0"
                >
                  <template v-slot:header>
                    <thead>
                    <tr>
                      <th v-if="$vuetify.breakpoint.mdAndUp">{{ $i18n.useScheduleModal.ID }}</th>
                      <th>{{ $i18n.useScheduleModal.MONTH }}</th>
                      <th>{{ $i18n.useScheduleModal.EARLY_PAYMENT_AMOUNT }}</th>
                      <th></th>
                    </tr>
                    </thead>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody v-if="items">
                    <tr v-for="(item, index) in items" :key="index" class="text-center">
                      <td v-if="$vuetify.breakpoint.mdAndUp">{{index + 1}}</td>
                      <td>
                        <fp-input
                            :clearable="false"
                            class="percent-input"
                            type="number"
                            int
                            v-model="item.month"
                            dense/>
                      </td>
                      <td>
                        <fp-input
                            :clearable="false"
                            class="percent-input"
                            type="number"
                            int
                            v-model="item.payment"
                            dense/>
                      </td>
                      <td><fp-btn text @click="scheduleStore.deleteEarlyPayment(index)"><v-icon>mdi-close</v-icon></fp-btn></td>
                    </tr>
                    </tbody>
                  </template>
                </v-data-table>
                <v-btn class="mt-4" outlined depressed @click="scheduleStore.addEarlyPayment()">{{ $i18n.useScheduleModal.ADD_EARLY_PAYMENT }}</v-btn>
              </template>
            </changes-detection>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup lang="ts">
import ChangesDetection from '@/components/commons/ChangesDetection.vue';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import {computed} from 'vue';
import {SimulationDemand} from '@/models/Demand';
import {debouncedAtInput} from '@/services/utils/BasicUtils';
import {useInstance} from '@/composables/useInstance';
import ScheduleAdditionalCostsTable from '@/components/calculator/schedule/ScheduleAdditionalCostsTable.vue';
import {I18NGetter} from "../../../services/enumTranslator/I18NGetter";

const props = defineProps<{
  value: SimulationDemand;
}>();

const emit = defineEmits<
    (event: 'update:modelValue', value: SimulationDemand) => void,
    (event: 'fetchSchedule') => void
>();

const scheduleStore = useScheduleStore();
const {schedule,} = scheduleStore;
const {$i18n, $env,} = useInstance();
const emitInput = (value: SimulationDemand) => {
  emit('update:modelValue', value);
};

const model = computed<SimulationDemand>({
  get: () => props.value,
  set: (newValue: SimulationDemand) => emitInput(newValue),
});

const fetchSchedule: void = debouncedAtInput(() => emit('fetchSchedule'));
</script>
<style scoped lang="scss">
$border: 1px solid fpShadow(.15) !important;

:deep(.v-expansion-panel:first-child > .v-expansion-panel-header) {
  border-top: $border;
}
:deep(.v-expansion-panel:not(:first-child) > .v-expansion-panel-header) {
  border-bottom: $border;
}
:deep(.v-label) {
  color: rgba(var(--v-fpShadow-rgb), 0.8)
}

</style>
