import {
  IMultiformDictionary,
  IMultiformDistrictDictionaryElement,
  IMultiformLandDictionaryElement
} from '@/modules/multiForm/shared/IMultiformDictionary';
import dictionaryStore from '@/commons/services/store/DictionaryStore';
import { BasicDictionary } from '@/commons/services/store/DictionaryStoreState';

export default function useGeoAreas() {
  const getAllDistricts = () => dictionaryStore.getDictionary('districtsDictionary');
  const getAllLands = () => dictionaryStore.getDictionary('landsDictionary');
  const getAllRegions = () => dictionaryStore.getDictionary('regionsDictionary');

  const getDistrictNameById = (id: number): string | undefined => getAllDistricts().find(x => x.id === id)?.name;
  const getLandNameById = (id: number): string | undefined => getAllLands().find(x => x.id === id)?.name;
  const getRegionById = (id: number = 1): BasicDictionary | undefined => getAllRegions().find(x => x.id === id);

  const landDictionary: () => IMultiformLandDictionaryElement<number>[] = () => {
    const allLands: IMultiformLandDictionaryElement<number>[] = getAllLands().map(x => ({ type: x.id, name_pl: x.name, regionId: x.regionId ?? undefined, }));
    return allLands;
  };
  const districtDictionary: (landId?: number) => IMultiformDistrictDictionaryElement[] = (landId?: number) => {
    const allDistricts: IMultiformDistrictDictionaryElement[] = getAllDistricts()
      .map(x => ({ type: x.id, name_pl: x.name, landId: x.landId, }));

    return allDistricts
      .filter(x => (landId && x.landId) ? x.landId === landId : true);
  };

  return {
    landDictionary,
    districtDictionary,
    getDistrictNameById,
    getLandNameById,
    getRegionById,
  };
}
