<template>
  <fp-message-wrapper
      v-if="message.type === messageTypes.CLIENT_ADDED"
      :date="message.createdDate"
      :user-name="userName"
      icon="mdi-account-plus-outline"
      color="success">
    <template slot="title">
      <p>{{I18NGetter().useFpMessage.CLIENT}} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.CLIENT_ADDED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CLIENT_REMOVED"
      :date="message.createdDate"
      :user-name="userName"
      color="error"
      :icon="`mdi-account-minus-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.CLIENT }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.CLIENT_REMOVED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.NOTE_ADDED"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-message-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.NOTE_ADDED }}
        <template v-if="message.data.user">
          <b>
            {{message.data.user.firstName}} {{message.data.user.lastName}}:
          </b>
        </template>
      </p>
    </template>
    <template slot="note">
      <template v-if="message.data.text">
        <p>{{message.data.text}}</p>
      </template>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.DEAL_TRANSFER"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-bank-transfer-out`">
    <template slot="title">
      <p v-if="message.data.msg">
        {{message.data.msg}} <br>
        {{ I18NGetter().useFpMessage.DELEGATING_EXPERT }}
        <b>{{message.data.from.agent ? message.data.from.agent.name : I18NGetter().useFpMessage.NO_EXPERT}}</b> <br>
        {{ I18NGetter().useFpMessage.DELEGATED_TO_EXPERT }}
        <b>{{message.data.to.agent ? message.data.to.agent.name : I18NGetter().useFpMessage.NO_EXPERT}}</b>
        <template v-if="message.data.to.organisation">
          {{ I18NGetter().useFpMessage.FROM_ORGANISATION }} <b>{{ message.data.to.organisation.name }}</b> <br>
        </template>
      </p>
      <p v-else>{{ I18NGetter().useFpMessage.DELEGATED_TO_ANOTHER_ORG }}
        <template v-if="message.data.user">
          <b>
            {{message.data.user.firstName}} {{message.data.user.lastName}}:
          </b>
        </template>
      </p>
    </template>
    <template slot="note">
      <template v-if="message.data.text">
        <p>{{message.data.text}}</p>
      </template>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACTIVITY_CREATED"
      color="info"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-calendar-check`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACTIVITY }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.ACTIVITY_CREATED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACTIVITY_REMOVED"
      color="info"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-delete`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACTIVITY }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.ACTIVITY_REMOVED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACTIVITY_UNDONE"
      color="warning"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-checkbox-marked-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACTIVITY }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.ACTIVITY_UNDONE }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACTIVITY_DONE"
      :date="message.createdDate"
      :user-name="userName"
      color="success"
      :icon="`mdi-minus-box-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACTIVITY }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.ACTIVITY_DONE }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.APPLICATION_ADDED"
      color="success"
      :date="message.createdDate"
      :user-name="userName"
      :icon="`mdi-briefcase-edit-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.APPLICATION }} <b>{{applicationName}}</b> {{ I18NGetter().useFpMessage.APPLICATION_ADDED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.APPLICATION_REMOVED"
      :date="message.createdDate"
      :user-name="userName"
      color="error"
      :icon="`mdi-briefcase-edit-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.APPLICATION }} <b>{{applicationName}}</b> {{ I18NGetter().useFpMessage.APPLICATION_REMOVED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CLOSED"
    :date="message.createdDate"
    :user-name="userName"
    color="error"
    :icon="`mdi-briefcase-edit-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.CLOSED_PROSPECT }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.APPLICATION_STATUS_CHANGED"
      :date="message.createdDate"
      :user-name="userName"
      color="warning"
      :icon="`mdi-repeat`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.APPLICATION }} <span class="font-weight-medium">{{applicationName}}</span>
        {{ I18NGetter().useFpMessage.APPLICATION_STATUS_CHANGED }}
        <span class="font-weight-medium">{{message.data.application.step.previousActivity | capitalize}}</span>
        {{ I18NGetter().useFpMessage.APPLICATION_STATUS_CHANGED_TO }}
        <span class="font-weight-medium">{{message.data.application.step.description | capitalize}}</span></p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.BACK_TO_AGENT_CC"
      :date="message.createdDate" color="info"
      :user-name="userName"
      :icon="`mdi-face-agent`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.BACK_TO_AGENT_CC }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ARCHIVED"
      :date="message.createdDate" color="error"
      :user-name="userName"
      :icon="`mdi-archive`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ARCHIVED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.HOUSEHOLD_UPDATED"
      :date="message.createdDate" color="info" :icon="`mdi-account-edit`"
      :user-name="userName">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.HOUSEHOLD_UPDATED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.HOUSEHOLD_CREATED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.HOUSEHOLD_CREATED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.HOUSEHOLD_REMOVED"
      :date="message.createdDate" color="info"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.HOUSEHOLD_REMOVED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CLIENT_CREATION_ALLOWED || message.type === messageTypes.CLIENT_CREATION_WITH_SCANS_ALLOWED"
      :date="message.createdDate" color="info"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.CLIENT_CREATION_ALLOWED }}</p>
      <p>{{ I18NGetter().useFpMessage.AVAILABLE_UNTIL }}: {{ message.data.clientCreationAllowedTo | dateTimeFormat }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_ADDED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>
        <span>{{ I18NGetter().useFpMessage.PARTNER_ADDED }}</span>
        <span v-if="userName"> {{ I18NGetter().useFpMessage.BY }} {{userName}}</span>
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>
        <span>{{ I18NGetter().useFpMessage.PARTNER_CHANGED }}</span>
        <span v-if="userName"> {{ I18NGetter().useFpMessage.BY }} {{userName}}</span>
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_REMOVED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>
        <span>{{ I18NGetter().useFpMessage.PARTNER_REMOVED }}</span>
        <span v-if="userName"> {{ I18NGetter().useFpMessage.BY }} {{userName}}</span>
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_EXPIRED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.PARTNER_EXPIRED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CLIENT_DATA_JSON_CHANGED"
      :date="message.createdDate" color="secondary"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.CLIENT_DATA }} <b>{{messageName}}</b> {{ I18NGetter().useFpMessage.HAS_BEEN_CHANGED }}</p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="message.type === messageTypes.NAME_CHANGED"
      :date="message.createdDate" color="company"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.NAME_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PESEL_CHANGED"
      :date="message.createdDate" color="warning"
      :user-name="userName"
      :icon="`mdi-account-plus-outline`">
    <template slot="title">
      <p>{{
          $i18nFormatter(
            I18NGetter().useFpMessage.NEW_TRANSLATION_PESEL_CHANGED,
            {personalIdNumberType: $env.getAppDomainConfig().personalIdNumber.type,}
          ) }} <b>{{messageName}}</b></p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.NIP_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.NIP_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.FIRST_NAME_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.FIRST_NAME_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BANK_SECRECY_ACCEPTED"
    :date="message.createdDate" color="success"
    :user-name="userName"
    :icon="`mdi-account-edit`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.BANK_SECRECY_ACCEPTED }}
        {{message.data.name}} {{ I18NGetter().useFpMessage.VALID_UNTIL }} {{message.data.expireAt | dateFormat}}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BANK_SECRECY_REJECTED"
    :date="message.createdDate" color="success"
    :user-name="userName"
    :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.BANK_SECRECY_REJECTED }} {{message.data.name}}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BANK_SECRECY_EMAIL_SENT"
    :date="message.createdDate" color="success"
    :user-name="userName"
    :icon="`mdi-account-edit`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.BANK_SECRECY_EMAIL_SENT }}
        {{message.data.partner.name}} {{ I18NGetter().useFpMessage.TO_ADDRESS }} {{message.data.sentTo}}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BANK_SECRECY_EXPIRED"
    :date="message.createdDate" color="success"
    :user-name="userName"
    :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.BANK_SECRECY_EXPIRED }} {{message.data.name}}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.LAST_NAME_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.LAST_NAME_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CLIENT_CREATED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.CLIENT_CREATED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.EMAIL_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.EMAIL_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PHONE_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-edit`">
    <template slot="title">
      <p>{{I18NGetter().useFpMessage.PHONE_CHANGED}}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.OWNER_ID_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-arrow-right-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.OWNER_ID_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACCEPTS_PROCESSING_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-alert-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACCEPTS_PROCESSING_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACCEPTS_RECEIVING_INFO_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-alert-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACCEPTS_RECEIVING_INFO_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACCEPTS_RECEIVING_OFFERS_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-account-alert-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.ACCEPTS_RECEIVING_INFO_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.STAGE_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-repeat`">
    <template slot="title">
      <p>
        <template v-if="message.data.stage">
          {{ I18NGetter().useFpMessage.STAGE_CHANGED }} <span class="font-weight-medium">{{message.data.stage.description}}</span>.
        </template>
        <template v-if="message.data.reason"><br>
          {{ I18NGetter().useFpMessage.REASON }} <span class="font-weight-medium">{{message.data.reason.name}}</span>
          <template v-if="message.data.reason.description"><br>
            {{ I18NGetter().useFpMessage.DESCRIPTION }} <span class="font-weight-medium">{{message.data.reason.description}}</span>
          </template>
        </template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.APPLICATION_SETTLED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-circle-multiple-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.APPLICATION_SETTLED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.SETTLED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-circle-multiple-outline`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.SETTLED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.STATUS_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-repeat`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.STATUS_CHANGED }}
        <span class="font-weight-medium">{{message.data.from.description}}</span> {{ I18NGetter().useFpMessage.TO }}
        <span class="font-weight-medium">{{message.data.to.description}}</span>
      </p>
      <span v-if="message.data.reason">
        <span class="font-weight-medium">{{ I18NGetter().useFpMessage.REASON_OF_REJECTION }}</span> <br>
        <p>{{reasonName}}</p>
        <p>{{message.data.reason.description}}</p>
      </span>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.DEAL_STATUS_CHANGED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-repeat`">
    <template slot="title">
      <p>{{ I18NGetter().useFpMessage.DEAL_STATUS_CHANGED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ATTACHMENTS_SENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p v-if="message.data">
        {{ I18NGetter().useFpMessage.ATTACHMENTS_SENT }} ({{message.data.fileNames.join(', ')}})
        {{I18NGetter().useFpMessage.FROM_EMAIL}}: {{message.data.emailSender}}
        {{ I18NGetter().useFpMessage.TO_CLIENTS }} {{message.data.emails.join(', ')}}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ATTACHMENTS_SENT_FOR_CLIENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p v-if="message.data">
        {{ I18NGetter().useFpMessage.ATTACHMENTS_SENT }} ({{message.data.fileNames.join(',')}})
        {{ I18NGetter().useFpMessage.TO_CLIENT }} <b>{{message.data.email}}</b>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.OFI_EMAIL_SENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.OFI_EMAIL_SENT }}
        <template v-if="message.data">{{ I18NGetter().useFpMessage.TO_EMAIL_ADDRESS }} <b>{{message.data.sentTo}}</b></template>
        <template v-if="message.data.sentByOrganisation">
          {{ I18NGetter().useFpMessage.FROM_ORGANISATION_COLON }} <b>{{message.data.sentByOrganisation.name}}
        </b></template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_OFI_EMAIL_SENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.PARTNER_OFI_EMAIL_SENT }}
        <template v-if="message.data">{{ I18NGetter().useFpMessage.TO_EMAIL_ADDRESS }} <b>{{message.data.sentTo}}</b></template>
        <template v-if="message.data.sentByOrganisation">
          {{ I18NGetter().useFpMessage.FROM_ORGANISATION_COLON }} <b>{{message.data.sentByOrganisation.name}}</b>
        </template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.RODO_EMAIL_SENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p>
        {{I18NGetter().useFpMessage.RODO_EMAIL_SENT}}
         <template v-if="message.data">{{ I18NGetter().useFpMessage.TO_EMAIL_ADDRESS }} <b>{{message.data.sentTo}}</b></template>
        <template v-if="message.data.sentByOrganisation">
          {{ I18NGetter().useFpMessage.FROM_ORGANISATION_COLON }} <b>{{message.data.sentByOrganisation.name}}</b>
        </template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_RODO_EMAIL_SENT"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template slot="title">
      <p>
        {{
          I18NGetter().useFpMessage.PARTNER_RODO_EMAIL_SENT
        }} <template v-if="message.data">{{ I18NGetter().useFpMessage.TO_EMAIL_ADDRESS }} <b>{{message.data.sentTo}}</b></template>
        <template v-if="message.data.sentByOrganisation">
          {{ I18NGetter().useFpMessage.FROM_ORGANISATION_COLON }} <b>{{message.data.sentByOrganisation.name}}</b>
        </template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.ACCEPTS_BANK_SECRECY_CHANGED"
    :date="message.createdDate" color="success"
    :user-name="userName"
    :icon="`mdi-email-outline`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.ACCEPTS_BANK_SECRECY_CHANGED }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACCEPTS_PROCESSING_INFO"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-email-outline`">
    <template v-slot:title v-if="message.data">
      <p v-if="message.data.acceptsProcessingMethod === agreementType.SMS">
        {{
          I18NGetter().useFpMessage.ACCEPTS_PROCESSING_INFO_SMS
        }} <b>{{message.data.phone}}</b>
      </p>
      <p v-if="message.data.acceptsProcessingMethod === agreementType.SCAN">
        {{ I18NGetter().useFpMessage.ACCEPTS_PROCESSING_INFO_SCAN }} <br /> <br />
        OFI: <b>{{message.data.ofiFile}}</b> <br />
        RODO: <b>{{message.data.rodoFile}}</b>
      </p>
      <p v-if="message.data.acceptsProcessingMethod === agreementType.PHONE" v-html-safe="I18NGetter().useFpMessage.ACCEPTS_PROCESSING_INFO_PHONE">
         <b>{{message.data.phone}}</b>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CREATED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      icon="mdi-seed-outline">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.CREATED_PROSPECT }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.STATUS_UPDATED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="$i18n.ProspectStatusIcon[message.data.status]">
    <template v-slot:title v-if="message.data">
      <p>{{ I18NGetter().useFpMessage.STATUS_UPDATED }}
        <span class="font-weight-medium"> {{$i18n.ProspectStatus[message.data.status]}}</span></p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.USERCOM_DEAL_ATTACHED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="`mdi-face-agent`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.USERCOM_DEAL_ATTACHED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.UPDATED"
      :date="message.createdDate" color="success"
      :user-name="userName"
      icon="mdi-repeat">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.UPDATED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.RETURNED_TO_CC"
      :date="message.createdDate" color="success"
      :user-name="userName"
      :icon="$i18n.ProspectStatusIcon[message.data.status]">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.RETURNED_TO_CC }}</p>
    </template>
    <template slot="note">
      <template v-if="message.data.returnToCcReason" slot="note">
        <p>{{ I18NGetter().useFpMessage.REASON }}</p> <br>
        <p>{{ message.data.returnToCcReason }}</p>
      </template>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.CONTACTED_AGAIN"
      :date="message.createdDate" color="success"
      :user-name="userName"
      icon="mdi-repeat">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.CONTACTED_AGAIN }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PARTNER_WELCOME_EMAIL_SENT"
      :date="message.createdDate"
      :user-name="userName"
      color="success"
      :icon="`mdi-email-outline`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.PARTNER_WELCOME_EMAIL_SENT }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ATTRIBUTES_UPDATED"
      :date="message.createdDate"
      :user-name="userName"
      color="success"
      :icon="`mdi-pencil-outline`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.ATTRIBUTES_UPDATED }}</p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.FOCUS_EVENT"
      :date="message.createdDate"
      :user-name="userName"
      color="success"
      :icon="`mdi-phone`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.FOCUS_EVENT }}</p>
    </template>
    <template #note>
      {{ I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_CALL_AT }}{{ message.data.callAt | timeFormat }} {{
        I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_PHONE_ATTEMPT
      }} {{ message.phone | phoneFormat }}
      {{ I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_FINISHED_WITH_STATUS }} {{
        $i18n.FocusStatus[message.data.status]
      }}.
      {{ message.data.isRecallPlanned ? I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_NEXT_ATTEMPT_PLANNED : '' }}
      {{
        message.data.duration !== 0 ? `${I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_PHONE_CALL_LASTED} ${Math.ceil(message.data.duration / 60)} ${I18NGetter().useFpMessage.FOCUS_EVENT_NOTE_MINUTES}` : ''
      }}
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.PRODUCT_REMOVED"
      :date="message.createdDate"
      :user-name="userName"
      color="success"
      :icon="`mdi-delete-sweep`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.PRODUCT_REMOVED }}</p>
    </template>
    <template #note>
      {{ I18NGetter().useFpMessage.PRODUCT }} <span class="font-weight-medium">{{message.data.productName}}</span>
      {{ I18NGetter().useFpMessage.PRODUCT_REMOVED_NOTE }}
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.DEAL_DELEGATED"
    :date="message.createdDate"
    :user-name="userName"
    :icon="`mdi-forum`">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.USER }} <span v-if="message.data.from" class="font-weight-medium">{{message.data.from.name}}</span>
        {{ I18NGetter().useFpMessage.DEAL_DELEGATED }}
        <template v-if="message.data.to.id === $user.id">
          {{ I18NGetter().useFpMessage.DELEGATED_TO_LOGGED_USER }}
        </template>
        <template v-else>
          {{ I18NGetter().useFpMessage.DELEGATED_TO_ANOTHER }} <span class="font-weight-medium">{{message.data.to.name}}</span>
        </template>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.ACCEPTS_BIG_CHANGED"
      :date="message.createdDate"
      color="success"
      :icon="`mdi-delete-sweep`">
    <template v-slot:title>
      <p>{{ I18NGetter().useFpMessage.ACCEPTS_BIG_CHANGED }}</p>
    </template>
    <template #note>
      {{ I18NGetter().useFpMessage.USER }} <b>{{message.user.name}}</b> {{ I18NGetter().useFpMessage.ACCEPTS_BIG_CHANGED_NOTE }}
    </template>
  </fp-message-wrapper>
   <fp-message-wrapper
    v-else-if="message.type === messageTypes.OFFICE_HOURS_UPDATED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.OFFICE_HOURS_UPDATED }}
      </p>
    </template>
    <template #note>
      <div v-for="(day, index) in Object.values($i18n.DayOfWeek)" :key="day">
        <b>{{day}}: </b>
        <span v-for="item in [{label: I18NGetter().useFpMessage.FROM, source: message.data.from},
         {label: I18NGetter().useFpMessage.TO, source: message.data.to}]" :key="item.label + index">
          <span>{{item.label}}</span>
          <v-icon v-if="item.source?.find(sourceItem => sourceItem.day === index + 1)?.isEnabled">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          <span>{{ (item.source?.find(item => item.day === index + 1)?.open || I18NGetter().useFpMessage.NONE).slice(0,5) }}</span>
          <span>-</span>
          <span>{{ (item.source?.find(item => item.day === index + 1)?.close || I18NGetter().useFpMessage.NONE).slice(0,5) }} </span>
        </span>
      </div>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BRANCH_ASSIGNED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.BRANCH_ASSIGNED }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.BRANCH_UNASSIGNED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.BRANCH_UNASSIGNED }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.OFFICE_ADDRESS_ADDED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.OFFICE_ADDRESS_ADDED }}
      </p>
    </template>
    <template #note>
      <p>
        {{ I18NGetter().useFpMessage.NEW_ADDRESS }}
        <span v-if="message.data.street">{{ message.data.street }}</span>
        <span v-if="message.data.streetNum">{{ message.data.streetNum }}</span>
        <span v-if="message.data.streetlocal">/{{ message.data.streetlocal }}</span>
        <span v-if="message.data.zip">, {{ message.data.zip | zipCodeFormat }}</span>
        <span v-if="message.data.city">, {{ message.data.city }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.OFFICE_ADDRESS_UPDATED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.OFFICE_ADDRESS_UPDATED }}
      </p>
    </template>
    <template #note>
      <p>
        {{ I18NGetter().useFpMessage.FROM_UPPER }}
        <span v-if="message.data.from.street">{{ message.data.from.street }}</span>
        <span v-if="message.data.from.streetNum">{{ message.data.from.streetNum }}</span>
        <span v-if="message.data.from.streetlocal">/{{ message.data.from.streetlocal }}</span>
        <span v-if="message.data.from.zip">, {{ message.data.from.zip | zipCodeFormat }}</span>
        <span v-if="message.data.from.city">, {{ message.data.from.city }}</span>
      </p>
      <p>
        {{ I18NGetter().useFpMessage.TO_UPPER }}
        <span v-if="message.data.to.street">{{ message.data.to.street }}</span>
        <span v-if="message.data.to.streetNum">{{ message.data.to.streetNum }}</span>
        <span v-if="message.data.to.streetlocal">/{{ message.data.to.streetlocal }}</span>
        <span v-if="message.data.to.zip">, {{ message.data.to.zip | zipCodeFormat }}</span>
        <span v-if="message.data.to.city">, {{ message.data.to.city }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.USER_BUSINESS_CARD_URL_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.USER_BUSINESS_CARD_URL_CHANGED }}
      </p>
    </template>
    <template #note>
      <p>
        {{ I18NGetter().useFpMessage.USER_BUSINESS_CARD_URL_CHANGED_NOTE }} <b>{{ message.data }}</b>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CLIENT_TRANSFER"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.USER }} <span v-if="userName" class="font-weight-medium">{{userName}}</span>
        {{ I18NGetter().useFpMessage.CLIENT_TRANSFER }} <span class="font-weight-medium">{{message.data.fromOrganisation.name}}</span>
        {{ I18NGetter().useFpMessage.TO_USER }} <span class="font-weight-medium">{{message.data.toUser.name}}</span>
        {{ I18NGetter().useFpMessage.AT_ORGANISATION }}
        <span class="font-weight-medium">{{message.data.toOrganisation.name}}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CAN_MANAGE_PROSPECTS_CHANGED ||
      message.type === messageTypes.CAN_MANAGE_WIDGETS_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{ I18NGetter().useFpMessage.CAN_MANAGE }}
        <span v-if="message.type === messageTypes.CAN_MANAGE_PROSPECTS_CHANGED">{{ I18NGetter().useFpMessage.PROSPECTS }}</span>
        <span v-else-if="message.type === messageTypes.CAN_MANAGE_WIDGETS_CHANGED">{{ I18NGetter().useFpMessage.WIDGETS }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CAN_MANAGE_MORTGAGE_CHANGED ||
      message.type === messageTypes.CAN_MANAGE_CASH_CHANGED ||
      message.type === messageTypes.CAN_MANAGE_SME_CHANGED ||
      message.type === messageTypes.CAN_MANAGE_INSURANCE_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.PRODUCT_FAMILIARITY}}
        <span v-if="message.type === messageTypes.CAN_MANAGE_MORTGAGE_CHANGED">{{ I18NGetter().useFpMessage.CAN_MANAGE_MORTGAGE_CHANGED }}</span>
         <span v-else-if="message.type === messageTypes.CAN_MANAGE_CASH_CHANGED">{{ I18NGetter().useFpMessage.CAN_MANAGE_CASH_CHANGED }}</span>
         <span v-else-if="message.type === messageTypes.CAN_MANAGE_SME_CHANGED">{{ I18NGetter().useFpMessage.CAN_MANAGE_SME_CHANGED }}</span>
         <span v-else-if="message.type === messageTypes.CAN_MANAGE_INSURANCE_CHANGED">{{ I18NGetter().useFpMessage.CAN_MANAGE_INSURANCE_CHANGED }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CAN_MEET_OUTSIDE_OFFICE_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.CAN_MEET_OUTSIDE_OFFICE}}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.PROSPECT_DELEGATION_UPDATED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.PROSPECT_DELEGATION_UPDATED }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.REQUIRE_KNF_QUIZ_CHANGED ||
    message.type === messageTypes.REQUIRE_AML_QUIZ_CHANGED ||
    message.type === messageTypes.REQUIRE_RODO_QUIZ_CHANGED ||
    message.type === messageTypes.REQUIRE_CYBER_QUIZ_CHANGED ||
    message.type === messageTypes.REQUIRE_OSH_QUIZ_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.TEST_PASS_REQUIREMENT }}
        <span v-if="message.type === messageTypes.REQUIRE_KNF_QUIZ_CHANGED">{{ I18NGetter().useFpMessage.KNF }}</span>
        <span v-else-if="message.type === messageTypes.REQUIRE_AML_QUIZ_CHANGED">{{ I18NGetter().useFpMessage.AML }}</span>
        <span v-else-if="message.type === messageTypes.REQUIRE_RODO_QUIZ_CHANGED">{{ I18NGetter().useFpMessage.RODO }}</span>
        <span v-else-if="message.type === messageTypes.REQUIRE_CYBER_QUIZ_CHANGED">{{ I18NGetter().useFpMessage.CYBERSECURITY }}</span>
        <span v-else-if="message.type === messageTypes.REQUIRE_OSH_QUIZ_CHANGED">{{ I18NGetter().useFpMessage.BHP }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.REQUIRE_ACCREDITATION"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.ACCREDITATION_REQUIREMENT}}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.USER_ACTIVATED ||
      message.type === messageTypes.USER_DEACTIVATED ||
      message.type === messageTypes.USER_CREATED ||
      message.type === messageTypes.USER_ACCREDITED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.USER }}
        <span v-if="message.type === messageTypes.USER_ACTIVATED">{{ I18NGetter().useFpMessage.ACTIVATED }}</span>
        <span v-else-if="message.type === messageTypes.USER_DEACTIVATED">{{ I18NGetter().useFpMessage.DEACTIVATED }}</span>
        <span v-else-if="message.type === messageTypes.USER_CREATED">{{ I18NGetter().useFpMessage.USER_CREATED }}</span>
        <span v-else-if="message.type === messageTypes.USER_ACCREDITED">{{ I18NGetter().useFpMessage.USER_FINISHED_ACREDITATION_PROCCESS }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.IS_HEAD_OF_ACCOUNTING_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{ I18NGetter().useFpMessage.SETTLEMENT_ACCESS }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.USER_REGISTERED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-account-plus">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.USER_REGISTERED }}
        <span v-if="message.data.isFromInvitation">{{ I18NGetter().useFpMessage.WITH_INVITATION }}</span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.IS_CHIEF"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.IS }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.IS_NOT }}</span>
        {{ I18NGetter().useFpMessage.CHIEF_ANY_GROUP }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.IS_HR_LOCKED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.HR_LOCKED }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.LANGUAGE_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span>
          {{ $i18nFormatter(I18NGetter().useFpMessage.NEW_TRANSLATION_LANGUAGE_CHANGED, {from: message.data.from, to: message.data.to,})
          }}
        </span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.HAS_ACCESS_TO_SETTLEMENT_VIEW"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        {{I18NGetter().useFpMessage.HAS_ACCESS_TO_SETTLEMENT_VIEW }}
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
    v-else-if="message.type === messageTypes.AGREEMENT_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-text-box-check-outline">
    <template slot="title">
      <p>
        <span v-if="message.data">
          Dodano zgodę: {{$env.getDomainAgreement(message.data.agreementType).title }}
        </span>
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CLIENT_DELEGATED_SUCCESSFULLY"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-account-check-outline">
    <template slot="title">
      <p>
        Klient został przekazany jako lead ubezpieczeniowy
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
    v-else-if="message.type === messageTypes.CLIENT_DELEGATED_FAILED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-account-cancel-outline">
    <template slot="title">
      <p>
        Nie udało się przekazać klienta jako leada ubezpieczeniowego
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.ONET_PREMIUM"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-tag">
    <template slot="title">
      <p>
        Wysłano link z dostępem do promocji Onet Premium do klienta na adres e-mail: <b>{{ message.data.email }}</b>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.REALISATION_AT_UPDATED"
      :date="message.createdDate"
      :user-name="userName"
      icon="mdi-pencil-outline">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.REALISATION_AT_UPDATED }}
        {{I18NGetter().useFpMessage.FROM}} {{ message.data.from | dateFormat }}
        {{I18NGetter().useFpMessage.TO}} {{ message.data.to | dateFormat }}
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
      v-else-if="message.type === messageTypes.LOAN_AMOUNT_UPDATED"
      :date="message.createdDate"
      :user-name="userName"
      icon="mdi-pencil-outline">
    <template slot="title">
      <p>
        {{ I18NGetter().useFpMessage.LOAN_AMOUNT_UPDATED }}
        {{I18NGetter().useFpMessage.FROM}} {{ message.data.from | numeralFormat }} {{$env.currentCurrency()}}
        {{I18NGetter().useFpMessage.TO}} {{ message.data.to | numeralFormat }} {{$env.currentCurrency()}}
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
    v-else-if="message.type === messageTypes.HAS_ACCESS_TO_LENDI_CARE || message.type === messageTypes.HAS_ACCESS_TO_LENDI_CARE_LEAD"
    :date="message.createdDate"
    :user-name="userName"
    :icon="message.data?.to ? 'mdi-account-check-outline' : 'mdi-account-cancel-outline'">
    <template slot="title">
      <p>
        <span v-if="message.data.to">{{ I18NGetter().useFpMessage.ADDED }}</span>
        <span v-else>{{ I18NGetter().useFpMessage.DELETED }}</span>
        <span>{{message.type === messageTypes.HAS_ACCESS_TO_LENDI_CARE ?
          I18NGetter().useFpMessage.ACCESS_TO_LENDI_CARE :
          I18NGetter().useFpMessage.ACCESS_TO_LENDI_CARE_LEAD }}
        </span>
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.REQUIRE_STMT_QUIZ_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    :icon="message.data.from === false ? 'mdi-alert-circle-outline' : 'mdi-alert-circle-check-outline'">
    <template v-slot:title>
      <p>{{message.data.from === false ? 'Włączono' : 'Wyłączono'}} wymagalność podpisania oświadczeń </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.IS_HR_LOCKED_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    :icon="message.data.from === false ? 'mdi-alert-circle-outline' : 'mdi-alert-circle-check-outline'">
    <template v-slot:title>
      <p>{{message.data.from === false ? 'Włączono' : 'Wyłączono'}} blokadę kadrową </p>
    </template>
    <template #note>
      <span class="overscript">{{message.data.message}}</span>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.IS_ACTION_REQUIRED_LOCKED"
    :date="message.createdDate"
    :user-name="userName"
    :icon="message.data.from === false ? 'mdi-lock-outline' : 'mdi-lock-open-outline'">
    <template v-slot:title>
      <p>{{message.data.from === false ? 'Włączono' : 'Wyłączono'}} blokadę systemową </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
    v-else-if="message.type === messageTypes.ATTACHMENT_EXPIRED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-lock">
    <template slot="title">
      <p>
        Dokument <b>{{message.data.fileName}}</b> wygasł i jest już niedostępny do podglądu oraz edycji przez użytkownika.
      </p>
    </template>
  </fp-message-wrapper>
  <fp-message-wrapper
    v-else-if="message.type === messageTypes.HAS_FILEMANAGER_ACCESS_CHANGED"
    :date="message.createdDate"
    :user-name="userName"
    icon="mdi-swap-horizontal">
    <template slot="title">
      <p>
        {{ message.data.from === false && message.data.to === true ?
        I18NGetter().useFpMessage.FILEMANAGER_ACCESS_GRANTED :
        I18NGetter().useFpMessage.FILEMANAGER_ACCESS_DENIED }}
      </p>
    </template>
  </fp-message-wrapper>

  <fp-message-wrapper
      v-else-if="!$env.isProd"
      :date="message.createdDate"
      :user-name="userName">
    <template v-slot:title>
      <p>{{message}}</p>
    </template>
  </fp-message-wrapper>
</template>

<script lang="ts">
import Vue from 'vue';
import {Prop, Component} from 'vue-property-decorator';
import FpMessageWrapper from '@/components/messages/FpMessageWrapper.vue';
import {FpMessageType} from '@/modules/deals/enums/FpMessageType';
import {IMessage} from '@/models/interfaces/IMessage';
import {AgreementType} from '@/modules/client/views/client-agreements/Agreements';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {numeralFormat} from '@/filters/StringFilters';

@Component({
  components: {FpMessageWrapper, },
})
export default class FpMessage extends Vue {
  public messageTypes = FpMessageType;
  public agreementType = AgreementType;

  public I18NGetter = I18NGetter;

  @Prop({required: true, type: Object, })
  public message!: IMessage<any, FpMessageType>;

  get messageName(): string {
    return this.message.data?.name || '';
  }

  get reasonName(): string {
    return this.message?.data?.reason?.name || I18NGetter().useFpMessage.NO_REASON;
  }

  get applicationName(): string {
    return this.message.data?.application?.name || I18NGetter().useFpMessage.NO_APPLICATION;
  }

  get userName(): string {
    return this.message.user?.name || '';
  }
}
</script>

<style scoped lang="scss">
p {
  line-height: 24px;
  margin-bottom: 0px;
}
</style>
