<template>
  <div>
    <v-layout wrap>
      <fp-select
          xs12
          md6
          prepend-icon="mdi-home-city-outline"
          :clearable="false"
          :label="I18NGetter().useMortgageSimulationFormStep1.LOAN_PURPOSE"
          :items="MultiformDictionaries.fFLoanPurposes"
          v-model="store.userInput.loanPurpose.activity"/>
      <fp-select
        v-if="isLoanPurposeWhatVisible"
        xs12
        md6
        mandatory
        :clearable="false"
        :label="I18NGetter().useMortgageSimulationFormStep1.MORTGAGE_REAL_ESTATE_TYPE"
        :prepend-icon="$vuetify.breakpoint.mdAndUp ? '' : 'none'"
        :items="collaterals"
        @input="onLoanPurposeWhatChange"
        v-model="store.userInput.loanPurpose.what"/>
      <fp-select
        v-else
        xs12
        md6
        :clearable="false"
        :label="I18NGetter().useMortgageSimulationFormStep1.COLLATERAL"
        :prepend-icon="$vuetify.breakpoint.mdAndUp ? '' : 'none'"
        :items="collaterals"
        v-model="store.userInput.collateral"/>
    </v-layout>
     <div class="mx-8 mb-2 py-2"
          v-if="isOtherCollateralVisible">
       <p class="all-caps-header mb-0">{{ I18NGetter().useMortgageSimulationFormStep1.IS_PURPOSE_AS_COLLATERAL }}</p>
       <v-layout>
         <fp-radio-group
           v-model="isPurposeAsCollateral"
           row
         ></fp-radio-group>
       </v-layout>
       <v-layout v-if="!isPurposeAsCollateral">
         <fp-select
           xs12
           md6
           :clearable="false"
           :label="I18NGetter().useMortgageSimulationFormStep1.COLLATERAL"
           :prepend-icon="$vuetify.breakpoint.mdAndUp ? '' : 'none'"
           :items="MultiformDictionaries.fFCollaterals"
           v-model="store.userInput.collateral"/>
       </v-layout>
     </div>
    <v-layout
      v-if="$env.getAppDomainConfig().calculator.hasMarketTypes && (store.isLoanPurposeActivityBuying || store.isLoanPurposeActivityBuyingAndRenovating)"
      wrap>
      <fp-radio-group
          xs12
          prepend-icon="none"
          :row="$vuetify.breakpoint.mdAndUp"
          v-model="store.userInput.loanPurpose.market"
          :items="MultiformDictionaries.marketTypesDictionary"/>
    </v-layout>
    <v-layout v-if="$env.getAppDomainConfig().calculator.hasFirstOrSubsequentRealEstateInfo" wrap>
      <fp-radio-group
        xs12
        prepend-icon="none"
        :row="$vuetify.breakpoint.mdAndUp"
        v-model="store.userInput.firstOrSubsequentRealEstate"
        :items="MultiformDictionaries.firstOrSubsequentRealEstateDictionary"/>
    </v-layout>
    <v-layout wrap>
      <fp-input
        v-if="store.isLoanPurposeActivityBuilding"
        xs12 md6
        prepend-icon="none"
        :label="I18NGetter().useMortgageSimulationFormStep1.CONSTRUCTION_PERIOD"
        type="number"
        :suffix="I18NGetter().useMortgageSimulationFormStep1.MONTHS_SUFFIX"
        required
        int
        v-model="store.userInput.constructionPeriod"/>
    </v-layout>
    <v-layout wrap>
      <fp-input
          xs12 md6
          prepend-icon="mdi-sigma"
          :label="I18NGetter().useMortgageSimulationFormStep1.TOTAL_AMOUNT_HYPOTHEC_VALUE"
          type="number"
          :suffix="$env.currentCurrency()"
          int
          :customRules="hypothecValueRules"
          v-model="store.userInput.hypothecValue"
          @input="onHypothecValueChange"
      />
    </v-layout>
    <v-layout wrap>
      <fp-input
        xs12 md6
        prepend-icon="mdi-cash-multiple"
        :label="I18NGetter().useMortgageSimulationFormStep1.LOAN_AMOUNT"
        :suffix="$env.currentCurrency()"
        type="number"
        int
        :customRules="loanAmountRules"
        v-model="store.userInput.loanAmount"
        @input="onLoanAmountChange"
      />
    </v-layout>
    <v-layout wrap>
      <v-flex>
        <v-radio-group v-model="savingsType" class="mt-0 py-0" hide-details="auto">
          <v-row class="align-center py-0">
            <v-col cols="6" class="py-0">
              <v-row class="align-center" no-gutters>
                <v-col cols="auto">
                  <v-icon>mdi-piggy-bank-outline</v-icon>
                </v-col>
                <v-col cols="auto" class="pl-3">
                  <v-radio :value="SavingsType.AMOUNT"/>
                </v-col>
                <v-col class="py-0">
                  <fp-input
                    class="px-0 py-0"
                    :label="`${I18NGetter().useMortgageSimulationFormStep1.SELF_CONTRIBUTION}`"
                    type="number"
                    :suffix="$env.currentCurrency()"
                    int
                    v-model="savingsAmount"
                    @input="onSavingsAmountChange"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-row class="align-center" no-gutters>
                <v-col cols="auto">
                  <v-radio :value="SavingsType.PERCENTAGE"/>
                </v-col>
                <v-col>
                  <fp-input
                    class="pl-0 py-0"
                    :label="`${I18NGetter().useMortgageSimulationFormStep1.SELF_CONTRIBUTION_SHORT}`"
                    type="number"
                    suffix="%"
                    int
                    :custom-rules="savingsPercentageRules"
                    v-model="savingsPercentage"
                    @input="onSavingsPercentageChange"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-flex>
    </v-layout>
    <high-ltv-alert :value="isFormValid" />
    <v-layout wrap>
      <fp-select
        xs12 md6
        :items="currencyDictionary"
        :label="I18NGetter().useMortgageSimulationFormStep1.CURRENCY"
        v-model="store.userInput.currency"
        prepend-icon="mdi-currency-usd"
        :clearable="false"
      />
    </v-layout>
    <v-layout wrap>
      <fp-radio-group
          xs12
          prepend-icon="none"
          :row="$vuetify.breakpoint.mdAndUp"
          v-model="store.userInput.fixedInstallments"
          :items="MultiformDictionaries.installmentTypeDictionary"/>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 md6>
        <v-layout wrap>
          <fp-input
              v-if="$env.isAppDomain().LENDI_PL"
              xs12
              prepend-icon="mdi-bridge"
              :label="I18NGetter().useMortgageSimulationFormStep1.BRIDGING_INSURANCE"
              type="number"
              :suffix="I18NGetter().useMortgageSimulationFormStep1.MONTHS_SUFFIX"
              int
              required
              v-model="store.userInput.explicitBridgingPeriod"/>
          <fp-input
              xs12
              prepend-icon="mdi-clock-outline"
              :label="I18NGetter().useMortgageSimulationFormStep1.LOAN_PERIOD"
              type="number"
              @input="onLoanPeriodInMonthsChange"
              :suffix="I18NGetter().useMortgageSimulationFormStep1.MONTHS_SUFFIX"
              int
              required
              :value="$env.isBackendSimV2 ? store.userInput.loanPeriod : store.userInput.loanPeriodInMonths"/>
          <v-flex xs12>
            <loan-period-slider
                @input="onSliderInput($event)"
                v-model="store.userInput.loanPeriod"/>
          </v-flex>
            <!-- TODO odblokowac w przyszlosci -->
          <v-flex class="mx-8" v-if="false">
              <div v-if="programOffersFilters?.length" class="mt-4 mb-2 all-caps-header">
               {{ I18NGetter().useSimulationNavigation.SHOW_OFFERS }}:
              </div>
              <offer-filter-checkbox
                v-for="filter in programOffersFilters"
                :key="filter.type"
                v-model="store.userInput.offerFilters[filter.type]"
                :filter="filter" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- TODO ODBLOKOWAC PO DODANIU OFERT -->
    <v-layout wrap v-if="$env.isAppDomain().LENDI_PL && false">
      <v-flex xs12  class="bx by pa-3 ma-4 rounded-xs">
        <v-layout wrap>
            <home-start-program-form :isFormValid="isFormValid"/>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import MultiformDictionaries, {currencyDictionary} from '@/modules/multiForm/shared/MultiformDictionaries';
import LoanPeriodSlider from '@/components/calculator/LoanPeriodSlider.vue';
import {debouncedAtInput} from '@/services/utils/BasicUtils';
import EnvironmentService from '@/env/EnvironmentService';
import {LoanPurposeWhat} from '@/models/simulation/LoanPurposeWhat';
import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';
import {LoanPurposeActivity} from '@/models/simulation/LoanPurposeActivity';
import {
  MORTGAGE_DEFAULT_LOAN_PERIOD_FOR_PURPOSE_LOAN_IN_YEARS,
  MORTGAGE_DEFAULT_LOAN_PERIOD_IN_YEARS
} from '@/models/Demand';
import HighLtvAlert from '@/components/calculator/HighLtvAlert.vue';
import {useCalculatorFormRules} from '@/components/calculator/useCalculatorFormRules';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import useOfferSorting, {FilterCategory, OfferFilters} from '@/components/calculator/results/OfferSorting';
import HomeStartProgramForm from '@/components/calculator/programs/Mortgage/HomeStartProgramForm.vue';
import OfferFilterCheckbox from '@/components/calculator/results/userInputPanel/OfferFilterCheckbox.vue';

const props = defineProps<{
  isFormValid: boolean,
}>();

enum SavingsType {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
};

const store = useSimulationStore();
const savingsAmount = ref<number>(store.userInput?.hypothecValue! - store.userInput?.loanAmount!);
const savingsPercentage = ref<number>(savingsAmount.value / store.userInput?.hypothecValue! * 100);
const savingsType = ref<SavingsType>(SavingsType.PERCENTAGE);
const {getVisibleFiltersByCategory,} = useOfferSorting();

const programOffersFilters = computed<OfferFilters[]>(
  () => getVisibleFiltersByCategory(FilterCategory.PROGRAM)
)

const {hypothecValueRules, loanAmountRules, savingsPercentageRules,} = useCalculatorFormRules();

const onHypothecValueChange = (value: number) => {
  savingsType.value === SavingsType.PERCENTAGE
    ? savingsAmount.value = Math.round(value * savingsPercentage.value / 100)
    : savingsPercentage.value = savingsAmount.value / value * 100;
  store.userInput.loanAmount! = Math.round(value - savingsAmount.value);
};

const onLoanAmountChange = (value: number) => {
  savingsAmount.value = Math.round(store.userInput?.hypothecValue! - value);
  savingsPercentage.value = savingsAmount.value / store.userInput?.hypothecValue! * 100;
};

const onSavingsAmountChange = (value: number) => {
  savingsType.value = SavingsType.AMOUNT;
  savingsPercentage.value = value / store.userInput?.hypothecValue! * 100;
  store.userInput.loanAmount! = Math.round(store.userInput?.hypothecValue! - value);
};

const onSavingsPercentageChange = (value: number) => {
  savingsType.value = SavingsType.PERCENTAGE;
  savingsAmount.value = Math.round(value / 100 * store.userInput?.hypothecValue!);
  store.userInput.loanAmount! = Math.round(store.userInput?.hypothecValue! - savingsAmount.value);
};
const onPurposeAsCollateralChange = (value: boolean) => {
  store.userInput!.purposeNotAsCollateral = !value;
  if (!value) {
    return;
  }
  handleLoanPurposeWhatSpecialCase(store.userInput!.loanPurpose.what!);
};

const handleLoanPurposeWhatSpecialCase = (value: LoanPurposeWhat) => {
  const isSpecialCase: boolean = [LoanPurposeWhat.HOUSE, LoanPurposeWhat.BUILDING_PLOT_AND_CONSTRUCTION,]
    .includes(value);
  store.userInput!.collateral = isSpecialCase
    ? LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE : value;
};

const isPurposeAsCollateral = computed<boolean>({
  get: () => !store.userInput?.purposeNotAsCollateral,
  set: onPurposeAsCollateralChange,
});

const collaterals = computed<IMultiformDictionary<LoanPurposeWhat>>(() => {
  return MultiformDictionaries.fFCollaterals
    .filter(item => getLoanPurposeWhatItems(store.userInput!.loanPurpose.activity)
      .includes(item.type));
});
const isOtherCollateralVisible = computed<boolean>(() => [
  LoanPurposeActivity.BUILDING,
  LoanPurposeActivity.BUYING,
  LoanPurposeActivity.RENOVATING,
  LoanPurposeActivity.BUYING_AND_RENOVATING,
  LoanPurposeActivity.REFINANCING_REAL_ESTATE_LOAN,
  LoanPurposeActivity.REFINANCING_NON_REAL_ESTATE_LOAN,
  LoanPurposeActivity.REFINANCING_RESIDENTIAL_REAL_ESTATE,
  LoanPurposeActivity.REFINANCING_NONRESIDENTIAL_REAL_ESTATE,
].includes(store.userInput?.loanPurpose.activity!));

watch(() => store.userInput!.loanPurpose.activity, () => {
  selectFirstAvailableLoanPurposeWhat();
  if (!store.deal) {
    setDefaultLoanPeriod();
  }
});

watch(() => isOtherCollateralVisible?.value, (value, oldValue) => {
  if (!oldValue && value && !store.userInput?.purposeNotAsCollateral) {
    store.userInput!.collateral = store.userInput!.loanPurpose.what!;
  }
});
const selectFirstAvailableLoanPurposeWhat = () => {
  const isSelectedLoanPurposeWhatInCollaterals = collaterals.value
    .some(item => item.type === store.userInput?.loanPurpose.what);
  if (!isSelectedLoanPurposeWhatInCollaterals) {
    store.userInput!.collateral = collaterals.value[0].type;
    store.userInput!.loanPurpose.what = isOtherCollateralVisible.value ? collaterals.value[0].type : null;
  }
};

const setDefaultLoanPeriod = () => {
  const loanPeriod = store.userInput?.loanPurpose.activity === LoanPurposeActivity.LOAN
    ? MORTGAGE_DEFAULT_LOAN_PERIOD_FOR_PURPOSE_LOAN_IN_YEARS : MORTGAGE_DEFAULT_LOAN_PERIOD_IN_YEARS;
  store.userInput!.loanPeriod = EnvironmentService.Environment.isBackendSimV2 ? loanPeriod * 12 : loanPeriod;
  store.userInput!.loanPeriodInMonths = loanPeriod * 12;
};

// To będzie do wykasowania po wprowadzeniu simV2
const onLoanPeriodInMonthsChange = debouncedAtInput(($event: number) => {
  if (EnvironmentService.Environment.isBackendSimV2) {
    store.userInput!.loanPeriod = $event;
  } else {
    store.userInput!.loanPeriod = Math.round($event / 12);
    store.userInput!.loanPeriodInMonths = $event;
  }
});
const onSliderInput = debouncedAtInput(($event: number) => {
  if (!EnvironmentService.Environment.isBackendSimV2) {
    store.userInput!.loanPeriodInMonths = $event ? $event * 12 : null;
  }
});

const getLoanPurposeWhatItems = (loanPurpose: LoanPurposeActivity) => {
  switch (loanPurpose) {
  case LoanPurposeActivity.BUYING:
    return [LoanPurposeWhat.APARTMENT, LoanPurposeWhat.HOUSE,
      LoanPurposeWhat.OFFICE, LoanPurposeWhat.HOLIDAY_HOME, LoanPurposeWhat.BUILDING_PLOT,
      LoanPurposeWhat.RECREATION_PLOT, LoanPurposeWhat.AGRICULTURAL_PLOT,];
  case LoanPurposeActivity.ADAPTATION:
  case LoanPurposeActivity.LOAN:
  case LoanPurposeActivity.HOME_DEBT_CONSOLIDATION_LOAN:
  case LoanPurposeActivity.CONSUMER_DEBT_CONSOLIDATION_LOAN:
    return [
      LoanPurposeWhat.APARTMENT, LoanPurposeWhat.OFFICE,
      LoanPurposeWhat.HOLIDAY_HOME, LoanPurposeWhat.RECREATION_PLOT,
      LoanPurposeWhat.BUILDING_PLOT, LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE,];
  case LoanPurposeActivity.BUILDING:
    return [LoanPurposeWhat.HOUSE, LoanPurposeWhat.OFFICE, LoanPurposeWhat.HOLIDAY_HOME,];
  case LoanPurposeActivity.BUYING_PLOT_AND_BUILDING:
    return [LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE, LoanPurposeWhat.HOLIDAY_HOME,];
  default:
    return [LoanPurposeWhat.APARTMENT, LoanPurposeWhat.HOUSE,
      LoanPurposeWhat.OFFICE, LoanPurposeWhat.HOLIDAY_HOME,];
  }
};

const isLoanPurposeWhatVisible = computed<boolean>(() => ![
  LoanPurposeActivity.ADAPTATION,
  LoanPurposeActivity.LOAN,
  LoanPurposeActivity.BUYING_PLOT_AND_BUILDING,
  LoanPurposeActivity.CONSUMER_DEBT_CONSOLIDATION_LOAN,
  LoanPurposeActivity.HOME_DEBT_CONSOLIDATION_LOAN,
].includes(store.userInput!.loanPurpose.activity));

const onLoanPurposeWhatChange = (value: LoanPurposeWhat) => {
  store.userInput!.collateral = value;
  handleLoanPurposeWhatSpecialCase(value);
};

</script>
<style scoped lang="scss">
:deep(.fp-radio-group) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.fp-checkbox) {
  .v-label {
    padding-left: 0;
  }

  label .label {
    width: 100%;

    span {
      font-size: 13px;
      font-style: normal;
    }
  }
}

.v-expansion-panel-header {
  height: 68px;
}

:deep(.v-btn-toggle) {
  display: flex;

  button {
    flex: 1;
  }
}

:deep(.v-form) {
  max-width: 100%;
}
</style>
