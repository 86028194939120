<template>
  <v-autocomplete
    :value="value"
    :items="banks"
    @input="onInput"
    :multiple="multiple"
    item-text="name_pl"
    ref="bankAutocomplete"
    :item-value="returnSymbol ? 'type' : 'id'"
    :return-object="returnObject"
    color="primary"
    hide-details
    :rules="required ? rules : undefined"
    :label="label || I18NGetter().useBankAutocomplete.CHOOSE_BANK"
    class="fp-input-outline"
    outlined
    small-chips
    hide-selected
    :prepend-icon="prependIcon || ''"
  >
    <template #prepend-item>
      <slot name="prepend-item" />
    </template>
    <template #selection="{ item }" v-if="multiple">
      <v-chip v-if="banks.length > 0 && value.length > 0" @click:close="removeBank(item.id)" close small>
        <span class="user-chip-text">
          {{ item.name_pl }}
        </span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { banksDictionary } from '@/commons/dictionaries/BanksDictionary';
import { BankDictionaryElement } from '@/modules/banks/models/BankDictionaryElement';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import { Utils } from '@/services/utils/BasicUtils';
import { ProductType } from '@/commons/enums/ProductType';

type Model = BankDictionaryElement | number;

@Component
export default class BankAutocomplete extends Vue {
  @Prop({ required: true, })
  public readonly value!: Array<Model> | Model;

  @Prop({ default: false, type: Boolean, })
  public readonly autoFocus!: boolean;

  @Prop({ default: false, type: Boolean, })
  public readonly returnId!: boolean;

  @Prop({ default: false, type: Boolean, })
  public readonly returnSymbol!: boolean;

  @Prop({ default: true, type: Boolean, })
  public readonly multiple!: boolean;

  @Prop({ default: 'mdi-account-search', type: [String, Boolean,], })
  public readonly prependIcon!: string | boolean;

  @Prop({ type: String, })
  public readonly label!: string;

  @Prop({ required: false, })
  public customDictionary?: Array<BankDictionaryElement> | null;

  @Prop({ default: false, type: Boolean, })
  public readonly required!: boolean;

  @Prop({ required: false, })
  public readonly productType!: ProductType;

  public rules = [(v: any) => !Utils.isEmptyValue(v) || 'Pole wymagane',];

  get banks(): BankDictionaryElement[] {
    const dictionary = this.customDictionary ? this.customDictionary : banksDictionary();
    const smeProductTypes: ProductType[] = [
      ProductType.SME,
      ProductType.INVESTMENT,
      ProductType.FACTORING,
    ];
    const productType: ProductType = smeProductTypes.includes(this.productType) ? ProductType.SME : this.productType;
    return this.productType ? dictionary.filter((item) => item.products![productType]) : dictionary;
  }

  public I18NGetter = I18NGetter;

  public async mounted() {
    setTimeout(() => {
      if (this.autoFocus) {
        const ref: any = this.$refs.bankAutocomplete;
        if (ref) {
          ref.focus();
        }
      }
    }, 50);
  }

  get returnObject(): boolean {
    return !this.returnId && !this.returnSymbol;
  }

  public removeBank(id: number) {
    if (Array.isArray(this.value)) {
      const index = this.value.findIndex((e: number | BankDictionaryElement) =>
        typeof e === 'number' ? e === id : e.id === id,
      );
      this.$emit('delete', this.value[index]);
      this.value.splice(index, 1);
      this.emitValue(this.value);
    }
  }

  private emitValue(value: Array<Model> | Model) {
    this.$emit('input', value);
  }

  public async onInput(event: Array<number> | Array<BankDictionaryElement>) {
    if (event) {
      this.emitValue(event);
    }
  }
}
</script>
