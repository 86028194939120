import { DictionaryStoreState } from '@/commons/services/store/DictionaryStoreState';
import { IStoreModule } from '@/store/store';

const state: DictionaryStoreState = {
  documentIncomeTypes: [],
  documentTypes: [],
  documentPeriods: [],
  documentValidityPeriods: [],
  partnerDictionary: [],
  prospectLossReasonDictionary: [],
  discussionTagsDictionary: [],
  divisionsDictionary: [],
  bankBranchesDictionary: [],
  landsDictionary: [],
  districtsDictionary: [],
  bankDictionary: [],
  multiDocumentCategoryDictionary: [],
  regionsDictionary: [],
};

export const DictionaryStoreModule: IStoreModule<DictionaryStoreState> = {
  namespaced: false,
  state,
  getters: {
    partnerDictionary(state) {
      return state.partnerDictionary;
    },
    bankDictionary(state) {
      return state.bankDictionary;
    },
    prospectLossReasonDictionary(state) {
      return state.prospectLossReasonDictionary;
    },
    discussionTagsDictionary(state) {
      return state.discussionTagsDictionary;
    },
    divisionsDictionary(state) {
      return state.divisionsDictionary;
    },
    bankBranchesDictionary(state) {
      return state.bankBranchesDictionary;
    },
    documentIncomeTypes(state) {
      return state.documentIncomeTypes;
    },
    documentPeriods(state) {
      return state.documentPeriods;
    },
    documentValidityPeriods(state) {
      return state.documentValidityPeriods;
    },
    documentTypes(state) {
      return state.documentTypes;
    },
    multiDocumentCategoryDictionary(state) {
      return state.multiDocumentCategoryDictionary;
    },
    districtsDictionary(state) {
      return state.districtsDictionary;
    },
    landsDictionary(state) {
      return state.landsDictionary;
    },
    regionsDictionary(state) {
      return state.regionsDictionary;
    },
  },
  mutations: {
    setDictionary(state, payload: { key: keyof DictionaryStoreState, dictionary: Array<any> }): void {
      state[payload.key].splice(0);
      payload.dictionary.map(element => state[payload.key].push(element));
    },
    addToDictionary<T extends DictionaryStoreState[keyof DictionaryStoreState][number]>(
      state: DictionaryStoreState,
      payload: { key: keyof DictionaryStoreState, newElement: T }): void {
      const stateElement: T[] = state[payload.key] as any;
      stateElement.push(payload.newElement);
    },
  },
};
