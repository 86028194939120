import {ProductType} from '@/commons/enums/ProductType';
import {LendiCareProductType} from '@/commons/enums/LendiCareProductType';
import {Utils} from '@/services/utils/BasicUtils';
import {BusinessCardType} from '@/models/enums/BusinessCardType';

export class AgentBusinessCardDTO {
  startingSellsYear: Record<ProductType, Nullable<number>> = {
    [ProductType.MORTGAGE]: null,
    [ProductType.CASH]: null,
    [ProductType.SME]: null,
    [ProductType.REAL_ESTATE]: null,
    [ProductType.NONBANK]: null,
    [ProductType.INSURANCE]: null,
    [ProductType.FACTORING]: null,
    [ProductType.LEASING]: null,
    [ProductType.INVESTMENT]: null,
    [ProductType.INSURANCE_MOTOR_VEHICLE]: null,
    [ProductType.INSURANCE_LIFE]: null,
    [ProductType.INSURANCE_REAL_ESTATE]: null,
    [ProductType.INSURANCE_TRAVEL]: null,
  };

  volume: Nullable<number> = null;
  happyCustomers: Nullable<number> = null;
  soldInsurancePolicies: Nullable<number> = null;
  aboutMe: Nullable<string> = null;
  businessCardUrl: Nullable<string> = null;
  businessCardsTypes: Nullable<BusinessCardType[]> = [];
  businessCardsDetails: PartialRecord<BusinessCardType, Record<LendiCareProductType, Nullable<number>>> = {
    [BusinessCardType.LENDI_CARE]: {
      [LendiCareProductType.COMMUNICATION]: null,
      [LendiCareProductType.COMPANY]: null,
      [LendiCareProductType.LIFE]: null,
      [LendiCareProductType.REAL_ESTATE]: null,
      [LendiCareProductType.INVESTMENT]: null,
      [LendiCareProductType.TRAVEL]: null,
    },
  };
}

export default class AgentBusinessCard extends AgentBusinessCardDTO {
  constructor(dto?: AgentBusinessCardDTO) {
    super();
    if (dto) {
      Utils.assign(this, dto);
    }
  }
}
