import { Gender } from '@/models/enums/Gender';

export const BikReportGradientColors = [
  '#FA7268',
  '#FB8459',
  '#FC954A',
  '#FDA63B',
  '#FEB82C',
  '#FFCA1C',
  '#D9CC1E',
  '#ABCC22',
  '#7ECC27',
  '#51CD2B',
];

export const bikLiabilityType = [
  { name_pl: 'Kredyt na zakup towarów, usług i papierów wartościowych', value: '01', },
  { name_pl: 'Kredyt niecelowy oraz studencki', value: '02', },
  { name_pl: 'Kredyt mieszkaniowy', value: '03', },
  { name_pl: 'Kredyt pracowniczy ', value: '04', },
  { name_pl: 'Kredyt odnawialny', value: '21', },
  { name_pl: 'Karta detaliczna', value: '22', },
  { name_pl: 'Karta kredytowa ', value: '23', },
  { name_pl: 'Karta charge', value: '24', },
  { name_pl: 'Karta debetowa', value: '25', },
  { name_pl: 'Limit debetowy w ROR', value: '26', },
  { name_pl: 'Hipoteczna linia kredytowa', value: '27', },
  { name_pl: 'Leasing ', value: '41', },
];

export enum BikRejectionReasons {
  NO_SIGN = 'NO_SIGN',
  UNREADABLE_SCAN = 'UNREADABLE_SCAN',
  WRONG_CLIENT = 'WRONG_CLIENT',
  INCORRECT_DATA = 'INCORRECT_DATA',
  OTHER = 'OTHER',
};

export const BikRejectionReasonsDictionary = [
  { name_pl: 'Brak podpisu', type: BikRejectionReasons.NO_SIGN, },
  { name_pl: 'Nieczytelny skan', type: BikRejectionReasons.UNREADABLE_SCAN, },
  { name_pl: 'Zgoda dotyczy innego klienta', type: BikRejectionReasons.WRONG_CLIENT, },
  { name_pl: 'Błędne dane na zgodzie', type: BikRejectionReasons.INCORRECT_DATA, },
  { name_pl: 'Inny', type: BikRejectionReasons.OTHER, },
];

export const MAX_BIK_SCORE = 720;
export const MIN_BIK_SCORE = 0;

export class LiabilitiesDetails {
  id?: number;
  liabilitiesDetailsType: string = '';
  accountsNumber?: number;
  totalFundingAmount?: number | null;
  totalOverdueAmount?: number | null;
  totalInstallmentAmount?: number | null;
}

export class LiabilitiesSummary {
  positiveLiabilitiesCount?: number;
  positiveTotalAmount?: number;
  positiveRepaidAmount?: number;
  overdueLiabilitiesCount?: number;
  overdueTotalAmount?: number;
  overdueAmount?: number;
}

export class Liability {
  id?: string;
  reportId?: number;
  bikId: number = 0;
  currency: string = '';
  exchangeRate?: number;
  amount?: number;
  dueDate?: Date;
  creditor?: string;
}

export class Liabilities {
  liabilitiesSummary: LiabilitiesSummary = new LiabilitiesSummary();
  installmentCredits?: LiabilitiesDetails | null;
  limits?: LiabilitiesDetails;
  other?: LiabilitiesDetails | null;
  leasing?: LiabilitiesDetails | null;
  guarantees?: LiabilitiesDetails | null;
}

export class OverdueLiabilities extends Liabilities {
  id: number = 0;
  overdueAmount: number = 0;
  contractType?: string;
  legalTitleDescription?: string;
}

export class FullfiledLiabilities extends Liabilities {
  repaidAmount: number = 0;
}

export class BikReport {
  summary!: BikReportSummary;
  overdueLiabilities: Array<OverdueLiabilities> = [];
  fullfiledLiabilities: Array<FullfiledLiabilities> = [];
  paymentStatusVectors: Array<BikPaymentVector> = [];

  get isNoScore(): boolean {
    return this.summary.score === 0;
  }

  get stepColor(): string {
    return this.isNoScore
      ? 'grey'
      : BikReportGradientColors[Math.round(this.scorePercentage / BikReportGradientColors.length)];
  }

  get score(): number {
    const score = this.summary?.score ?? MIN_BIK_SCORE;
    return score < MIN_BIK_SCORE
      ? MIN_BIK_SCORE
      : score > MAX_BIK_SCORE
        ? MAX_BIK_SCORE
        : score;
  }

  get scorePercentage(): number {
    return (this.score - MIN_BIK_SCORE) * 100 / (MAX_BIK_SCORE - MIN_BIK_SCORE);
  }

  constructor(dto?: BikReport) {
    if (dto) {
      dto.summary = new BikReportSummary(dto.summary);
      dto.paymentStatusVectors = dto.paymentStatusVectors.map(x => new BikPaymentVector(x));
      Object.assign(this, dto);
    }
  }
}

export class BikPerson {
  id?: number;
  uuid?: string;
  name?: string;
  correspondenceAddress?: string;
}

export class BikNaturalPerson extends BikPerson {
  pesel?: string;
  identityDocumentType?: string;
  identityDocumentId?: string;
  residenceAddress?: string;
  correspondenceAddress?: string;

  get fullName(): string {
    return `${this.name ?? ''}`.trim();
  }
}

export class BikLegalPerson extends BikPerson {
  id?: number;
  uuid?: string;
  name?: string;
  nip?: string;
  regon?: string;
  officeAddress?: string;
  correspondenceAddress?: string;
  plenipotentiaries: Array<string> = [];

  get fullName(): string {
    return `${this.name ?? ''}`.trim();
  }
}

export class BikReportSummary {
  constructor(dto?: BikReportSummary) {
    Object.assign(this, dto);
  }

  id?: number;
  inquiryId: number = 0;
  clientId: number = 0;
  statusMsg?: string | null;
  reportName: string = '';
  client: BikNaturalPerson | BikLegalPerson = new BikNaturalPerson();
  score?: number;
  probabilityOfNotPaying?: number;
  odds?: number;
  liabilities?: Liabilities;
  createdDate?: string;
  queriesInLast30Days?: number = 0;
  queriesInLast90Days?: number = 0;
  queriesInLast365Days?: number = 0;

  public get gender(): Gender | null {
    if (this.client instanceof BikNaturalPerson && this.client.pesel) {
      return Number(this.client.pesel[9]) % 2 === 0 ? Gender.FEMALE : Gender.MALE;
    }
    return null;
  }

  public get initials(): string {
    return this.client.fullName
      .split(' ')
      .map(el => el[0])
      .join('')
      .toUpperCase();
  }

  public get avatarUrl(): string {
    return this.gender && this.gender === Gender.MALE
      ? require('@/assets/img/crew/m.svg')
      : require('@/assets/img/crew/k.svg');
  }
}

export class BikReportObligation {
  id: number = 0;
  reportId: number = 0;
  obligationType: number = 0;
  infoMonitorId: string = '';
  creditor: string = '';
  creditType?: string = '';
  creditDate?: Date;
  creditAmount?: number = 0;
  amountToPay: number = 0;
  status?: string = '';
}

export class BikPaymentVector {
  id?: number;
  reportId: number = 0;
  accountId: number = 0;
  accountType: string = '';
  statusVector: string[] = [];

  get typeCodeName(): string {
    const liabilityType = bikLiabilityType.find(code => code.value === this.accountType);
    return liabilityType ? liabilityType.name_pl : '';
  }

  constructor(dto?: BikPaymentVector) {
    Object.assign(this, dto);
  }
}
