<template>
  <v-data-table
      class="costs-table"
      disable-pagination
      :headers="tableHeaders"
      :items="tableItems"
      hide-default-footer
      disable-sort
      calculate-widths
      mobile-breakpoint="0">
    <template v-slot:body="{ items }">
      <tbody v-if="items">
      <tr class="border" v-for="(item, index) in items" :key="index">
        <td>{{item.name}}</td>
        <td v-html="item.fee"></td>
        <td>{{item.total}}</td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td class="font-weight-bold">{{additionalCostsSum | numeralFormat}} {{$env.currentCurrency()}}</td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import {numeralFormat} from '@/filters/StringFilters';
import {computed} from 'vue';
import {Schedule, ScheduleCostType, SummaryCost} from '@/components/calculator/schedule/Schedule';
import {useInstance} from '@/composables/useInstance';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import {Utils} from '@/services/utils/BasicUtils';
import {sum} from 'lodash-es';

const {keys,} = Utils;
const scheduleStore = useScheduleStore();
const {$i18n, $env,} = useInstance();

type TableHeaderValueType = 'title' | 'fee' | 'total';

const tableHeaders: {text: string | null, value: TableHeaderValueType,}[] = [
  {text: null, value: 'title',},
  {text: $i18n.useScheduleModal.TABLE_HEADER_PAYMENT, value: 'fee',},
  {text: $i18n.useScheduleModal.TABLE_HEADER_SUM, value: 'total',},
];

const schedule = computed<Schedule | null>(() => scheduleStore.schedule);

const tableItems = computed<{name: string, fee: string, total: string}[]>(() => {
  const getKeyByValue = (value: string): keys<ScheduleCostType> => {
    return Object.keys(ScheduleCostType).find((key) => ScheduleCostType[key] === value) as keys<ScheduleCostType>;
  }
  return schedule.value?.summaryCosts?.length ? schedule.value.summaryCosts.map((x: SummaryCost) => {
    const nameKey = getKeyByValue(x.name);
    const name = $i18n.useScheduleModal[nameKey!] ?? 'No key';
    const fee: string = x.costs.map((cost) => `${numeralFormat(cost.fee)} ${$env.currentCurrency()}<br> <span class=" body-3">${cost.description}</span> <br>`).toString().replace(',', '');
    return {
      name,
      fee,
      total: `${numeralFormat(x.total)} ${$env.currentCurrency()}`,
    };
  }) : [];
})

const additionalCostsSum = computed<number>(() => {
  return schedule?.value?.summaryCosts?.length ? sum(schedule.value.summaryCosts.map(cost => cost.total)) : 0;
});
</script>
<style scoped lang="scss">
.costs-table {
  $even-row-bg-color: rgba($primary, .08);
  $row-height: 40px;
  $early-payment-row-height: 56px;
  $border: 1px solid fpShadow(.15) !important;

  counter-reset: page;

  ::v-deep {
    tbody td, th, tr {
      padding: 0 8px !important;
      height: $row-height;
      border-right: $border;
    }

    tr:last-child td, tr:last-child th {
      border-bottom: $border; /* Add border to last row */
    }

    thead tr th {
      background-color: $even-row-bg-color;
      color: rgba(var(--v-fpShadow-rgb), 0.8) !important;
    }

    thead tr th:not(:first-child) {
      border-top: $border;
    }

    thead tr th:first-child {
      background-color: transparent;
      border-left: hidden;
    }

    tbody tr:nth-child(even):not(:last-child) {
      background-color: $even-row-bg-color !important;
    }

    tbody tr.border td:first-child {
      border-left: $border;
      border-bottom: $border;
      color: rgba(var(--v-fpShadow-rgb), 0.8) !important;
      font-weight: 500;
    }

    tbody tr td:first-child {
      border-bottom: none !important;
    }

    tbody tr td:last-child {
      text-align: right;
    }

  }
}
</style>
